import { get, patch, post } from "../helpers/admin_api_helper";
import { get as getUser } from "../helpers/user_api_helper";

export const signIn = (data: {}) => post(`/admin/sign-in`, data);
export const getAll = () => get(`/admin`);
export const invite = (data: {}) => post(`/admin/invite`, data);
export const verifyToken = (token: string) =>
  post(`/admin/verify-token`, { token: token });
export const updateAdmin = (data: { id: number }) =>
  patch(`/admin/${data.id}`, data);
export const sendQuote = (data: {}) => post(`/admin/send-quote`, data);
export const resetPassword = (data: {}) => post(`/admin/reset-pass`, data);
export const forgetPassword = (data: {}) => post(`/admin/forgot-pass`, data);

export const getUserAll = () => getUser(`/admin`);
