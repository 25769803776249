import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

type Props = {
  isLoading: boolean;
};

export const Loader: React.FC<Props> = ({ isLoading }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {isLoading ? <CircularProgress /> : <div>Data loaded</div>}
    </Box>
  );
};
