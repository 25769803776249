import * as React from "react";
import TextField from "@mui/material/TextField";

interface InputProps {
  label?: string;
  name?: string;
  id?: string;
  autoComplete?: string;
  value?: string;
  defaultValue?: string | number;
  type?: string;
  inputProps?: any;
  InputProps?: any;
  InputLabelProps?: any;
  width?: number;
  size?: "small" | "medium" | undefined;
  rows?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  error?: boolean;
  sx?: any;
  className?: any;
}

export const Input: React.FC<InputProps> = ({
  label,
  name,
  id,
  autoComplete,
  value,
  type,
  width,
  rows,
  size = "small",
  inputProps,
  InputProps,
  InputLabelProps,
  onChange,
  required,
  error,
  sx,
  defaultValue,
  className,
}) => {
  return (
    <TextField
      variant="outlined"
      required={required}
      autoComplete={autoComplete}
      fullWidth
      label={label}
      name={name}
      id={id}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      size={size}
      type={type}
      inputProps={inputProps}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      style={{ width: width }}
      rows={rows}
      multiline={rows ? true : false}
      error={error}
      helperText={error}
      sx={sx}
      className={className}
    />
  );
};
