import { del, get, post, put } from "../helpers/admin_api_helper";
import { get as userGet } from "../helpers/user_api_helper";

export const getModels = () => get(`/model`);
export const getModelByID = (data: { ID?: string }) => get(`/model/${data.ID}`);
export const createModel = (data: {}) => post(`/model`, data);
export const updateModel = (data: { ID?: string }) =>
  put(`/model/${data.ID}`, data);
export const deleteModel = (data: { ID?: string }) => del(`/model/${data.ID}`);
export const getSearchModels = (data: {}) => post(`/model/searchAll`, data);

export const getUserModels = () => userGet(`/model`);
export const getUserModelByID = (data: { ID?: string }) =>
  userGet(`/model/${data.ID}`);
