import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Grid, Typography, Box } from "@mui/material";
import { Input } from "../../../components/Input";
import { forgetPassword } from "../../../apis/dealer";

export const UserForgotPasswordPage: React.FC = () => {
  const [emailError, setEmailError] = useState(true);
  const [error, setError] = useState<string>("");

  const handleEmailChange = (e: any) => {
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setError("");
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (emailError) {
      setError("Form is invalid! Please check the fields...");
    } else {
      const formData = new FormData(e.target);
      const formValues: any = Object.fromEntries(formData.entries());

      for (const key in formValues) {
        if (formValues[key] === "") {
          delete formValues[key];
        }
      }

      try {
        let res = await forgetPassword(formValues);
        if (!res?.success) {
          alert(res?.email);
        } else {
          alert("Check your email.");
        }
      } catch (err) {
        console.error("Sever error.", "error");
      }
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={9}
        sx={{
          background: "url(../user_login_bg.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
      <Grid
        item
        xs={3}
        sx={{
          background: "#212121",
          height: "100vh",
          p: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <Typography
              variant="h4"
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box component="span" sx={{ color: "#ffd700" }}>
                Forgot Password
              </Box>
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              {error && (
                <Typography variant="body2" color="error" sx={{ mb: 1.5 }}>
                  {error}
                </Typography>
              )}
              <Input
                id="email"
                label="Email Address"
                name="email"
                size="medium"
                required
                autoComplete="email"
                onChange={(e) => handleEmailChange(e)}
                InputProps={{
                  style: { color: "#fff" },
                }}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Send
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item sx={{ mt: 2, color: "#FFFFFF" }}>
              <RouterLink
                to="/user/login"
                className="custom-link"
                style={{ color: "#FFFFFF" }}
              >
                Go to Sign in
              </RouterLink>
            </Grid>
          </Grid>
          {/* {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )} */}
        </form>
      </Grid>
    </Grid>
  );
};
