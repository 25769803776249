import { useState, useEffect, useCallback } from "react";

import { getCompany, getUserCompany } from "../apis/company";
import { CompanyProps } from "../constants/Interface";

export const useCompanyData = (apiType = "admin"): any => {
  const [company, setCompany] = useState<CompanyProps | null>(null);

  const fetchCompany = useCallback(async () => {
    try {
      let companyData;
      if (apiType === "admin") {
        companyData = await getCompany();
      } else {
        companyData = await getUserCompany();
      }
      setCompany(companyData?.[0]);
    } catch (error) {
      console.error("Failed to fetch company", error);
    }
  }, []);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  return { company };
};
