import { del, get, post, patch } from "../helpers/admin_api_helper";
import {
  get as getUser,
  patch as patchUser,
  post as postUser,
} from "../helpers/user_api_helper";

export const getDealers = () => get(`/dealer`);
export const findDealersAndQuotesAll = (data: {}) =>
  post("/dealer/findDealersAndQuotesAll", data);
export const getDealerByID = (data: { ID?: string }) =>
  get(`/dealer/${data.ID}`);
export const updateDealer = (data: { ID?: string }) =>
  patch(`/dealer/${data.ID}`, data);
export const deleteDealer = (data: { ID?: string }) =>
  del(`/dealer/${data.ID}`);

export const inviteDealer = (data: {}) => post(`/dealer/invite`, data);
export const verifyToken = (token: string) =>
postUser(`/dealer/verify-token`, { token: token });
export const resetPassword = (data: {}) => postUser(`/dealer/reset-pass`, data);
export const forgetPassword = (data: {}) => postUser(`/dealer/forget-pass`, data);

export const signIn = (data: {}) => postUser(`/dealer/sign-in`, data);

export const getUserDealerByID = (data: { ID?: string }) =>
  getUser(`/dealer/${data.ID}`);
export const updateUserDealer = (data: { ID?: string }) =>
  patchUser(`/dealer/${data.ID}`, data);
export const sendQuote = (data: {}) => postUser(`/admin/send-quote`, data);
