import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { Input } from "../../../components/Input";
import { SelectComponent } from "../../../components/Select";
import { CompanyProps, TabProps } from "../../../constants/Interface";
import {
  createCompany,
  getCompany,
  updateCompany,
} from "../../../apis/company";
import { MenuItemData } from "../../../types/global.types";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { SelectTag } from "../../../components/Tag/SelectTab";
import { getAll } from "../../../apis/admin";
import { usePermissionHook } from "../../../hook/usePermissionHook";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const menuItems: MenuItemData[] = [
  { value: "day", label: "Day(s)" },
  { value: "month", label: "Month(s)" },
  { value: "year", label: "Year(s)" },
];

export const SettingsTab: React.FC<TabProps> = ({ value }) => {
  const [company, setCompany] = useState<CompanyProps | null>(null);
  const [tags, setTags] = useState([]);
  const [admins, setAdmins] = useState([]);

  const { showSnackBar } = useGlobalSnackBar();
  const { permissionReadOnly } = usePermissionHook();

  const fetchAdmins = async () => {
    try {
      const companyData = await getCompany();
      setCompany(companyData?.[0]);
      setTags(
        companyData?.[0]?.to_copy_admins
          ? JSON.parse(companyData?.[0]?.to_copy_admins)
          : []
      );

      const adminsData = await getAll();
      setAdmins(adminsData.map((res: any) => ({ ...res, ID: res.id })));
    } catch (error) {
      console.error("Failed to fetch company", error);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  const permissionErrorMessage = () => {
    showSnackBar("Not allowed permission!", "error");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());
    if (!company?.ID) {
      const res = await createCompany({
        ...formValues,
        to_copy_admins: JSON.stringify(tags),
      });
      showSnackBar("Created new settings");
    } else {
      let reFormValues = {
        ...formValues,
        ID: company.ID,
        to_copy_admins: JSON.stringify(tags),
      };
      const res = await updateCompany(reFormValues);
      showSnackBar("Updated this settings");
    }
    fetchAdmins();
  };
  return (
    <CustomTabPanel value={value} index={1}>
      <Box sx={{ flexGrow: 1 }} component="form" onSubmit={handleSubmit}>
        <Typography variant="h5" align="center" mt={2.5} mb={1.5}>
          Active Dealer Settings
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2.5,
            mb: 1.5,
            gap: 1,
            justifyContent: "center",
          }}
        >
          <div style={{ width: "20%", textAlign: "center" }}>
            Mark dealers as inactive after
          </div>
          <Input
            type="number"
            inputProps={{
              min: 1,
              max: 999,
            }}
            width={60}
            defaultValue={company?.active_dealer_setting_number}
            name="active_dealer_setting_number"
          />
          <SelectComponent
            name="active_dealer_setting_date"
            menuItems={menuItems}
            width="60px"
            defaultValue={company?.active_dealer_setting_date}
          />
        </Typography>

        <Typography variant="h5" align="center" mt={2.5} mb={1.5}>
          Quote Expiration Setting
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2.5,
            mb: 1.5,
            gap: 1,
            justifyContent: "center",
          }}
        >
          <div style={{ width: "20%", textAlign: "center" }}>
            Quotes Expire After
          </div>
          <Input
            type="number"
            inputProps={{
              min: 1,
              max: 99,
            }}
            width={60}
            defaultValue={company?.quote_expiration_setting_number}
            name="quote_expiration_setting_number"
          />
          <SelectComponent
            name="quote_expiration_setting_date"
            menuItems={menuItems}
            width="60px"
            defaultValue={company?.quote_expiration_setting_date}
          />
        </Typography>

        <Typography variant="h5" align="center" mt={2.5} mb={1.5}>
          Tax & Discounts
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2.5,
            mb: 1.5,
            gap: 1,
            justifyContent: "center",
          }}
        >
          <div style={{ width: "20%" }}>Qty Discount Tier 1</div>
          <div style={{ width: "20%" }}>5-9 Units</div>
          <Input
            type="number"
            inputProps={{
              min: 1,
              max: 10,
            }}
            width={60}
            defaultValue={company?.qty_discount_1}
            name="qty_discount_1"
          />
          <div>%</div>
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2.5,
            mb: 1.5,
            gap: 1,
            justifyContent: "center",
          }}
        >
          <div style={{ width: "20%" }}>Qty Discount Tier 2</div>
          <div style={{ width: "20%" }}>10+ Units</div>
          <Input
            type="number"
            inputProps={{
              min: 1,
              max: 10,
            }}
            width={60}
            defaultValue={company?.qty_discount_2}
            name="qty_discount_2"
          />
          <div>%</div>
        </Typography>

        <Typography variant="h5" align="center" mt={2.5} mb={1.5}>
          To send the quote copies to
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2.5,
            mb: 1.5,
            gap: 1,
            justifyContent: "center",
          }}
        >
          <SelectTag tags={tags} setTags={setTags} availableTags={admins} />
        </Typography>
        {/* <Typography
          variant="h6"
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2.5,
            mb: 1.5,
            gap: 1,
            justifyContent: "center",
          }}
        >
          <div>Taxed State: </div>
          <SelectComponent
            menuItems={usStates}
            name="taxed_state"
            width="60px"
            defaultValue={company?.taxed_state}
          />
          <div style={{ marginLeft: "5%" }}>Sales Tax %</div>
          <Input
            type="number"
            inputProps={{
              min: 1,
              max: 10,
            }}
            width={120}
            defaultValue={company?.sales_tax_percentage}
            name="sales_tax_percentage"
          />
        </Typography> */}

        <Typography variant="h5" align="center" mt={2.5} mb={1.5}>
          Default Dealer Invite Message:
        </Typography>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2.5,
            mb: 1.5,
            gap: 1,
            justifyContent: "center",
          }}
        >
          <Input
            label="Message"
            name="invite_message_description"
            sx={{ mb: 2, width: "40%" }}
            rows={5}
            defaultValue={
              company?.invite_message_description ||
              `Thank you for your interest and loyalty to BigFoot! Find the sign-up link below to gain access to our exclusive dealer portal!`
            }
          />
        </Typography>

        <Typography align="center" mt={5.5} mb={1.5}>
          <Button sx={{ width: "150px" }} variant="contained" type="submit">
            Save
          </Button>
        </Typography>
      </Box>
    </CustomTabPanel>
  );
};
