const currentDate = new Date();
const isoDateString = currentDate.toISOString();

export const checkActiveStatus = (
  createdDate: string,
  range: string,
  quote_number: number
) => {
  if (quote_number > 0) return "Active";

  const current: any = new Date(isoDateString);
  const created: any = new Date(createdDate);

  const difference = current - created;

  let rangeInMs;
  const rangeValue = parseInt(range);
  if (range.includes("month")) {
    rangeInMs = rangeValue * 30 * 24 * 60 * 60 * 1000; // Approximating 1 month as 30 days
  } else if (range.includes("year")) {
    rangeInMs = rangeValue * 365 * 24 * 60 * 60 * 1000; // Approximating 1 year as 365 days
  } else if (range.includes("day")) {
    rangeInMs = rangeValue * 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  } else {
    throw new Error("Unsupported range format");
  }

  if (difference > rangeInMs) {
    return "Inactive";
  }
  return "Active";
};