import axios from "axios";

//pass new generated access token here
const user = localStorage.getItem("user_access_token");
const token = "Bearer " + user;

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.data.message === "Unauthorized"
    ) {
      localStorage.removeItem("user_access_token");
      window.location.href = "/user/login";
    }
    Promise.reject(error);
  }
);

export async function get(url: string, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url: string, data: {}, config = {}) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url: string, data: {}, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function patch(url: string, data: {}, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
