import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import ReplyIcon from "@mui/icons-material/Reply";

import DefaultTable from "../../../components/Table/DefaultTable";
import { Input } from "../../../components/Input";
import { getQuoteAll, updateQuote } from "../../../apis/quote";
import { useCompanyData } from "../../../hook/useCompanyData";
import { Loader } from "../../../components/Loader";
import { CustomDialog } from "../../../components/modal/CustomDialog";
import { SelectComponent } from "../../../components/Select";
import { MenuItemData, ModelOptionDataType } from "../../../types/global.types";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { Model, generateRandomId } from "../../../utils/model";
import { useModelData } from "../../../hook/useModelData";
import { setQuoteAdminModels } from "../../../store/actions/adminAuthActions";
import { usePermissionHook } from "../../../hook/usePermissionHook";

interface StatCardProps {
  value: number | string;
  description: string;
}

const menuItems: MenuItemData[] = [
  { value: "Pending", label: "Pending" },
  { value: "Sold", label: "Sold" },
  { value: "Expired", label: "Expired" },
];

const StatCard: React.FC<StatCardProps> = ({ value, description }) => (
  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
    <Card sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}>
      <CardContent sx={{ pb: "10px !important" }}>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

const headerData: string[] = [
  "Dealer",
  "Contact",
  "Quote #",
  "Date",
  "Status",
  "",
];

export const Quotes: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { company } = useCompanyData();

  const { showSnackBar } = useGlobalSnackBar();
  const { permissionReadOnly } = usePermissionHook();
  const { models } = useModelData();

  const [isLoading, setIsLoading] = useState<boolean | false>(true);
  const [quotes, setQuotes] = useState([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editQuote, setEditQuote] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [lastMonthQuotesNumber, setLastMonthQuotesNumber] = useState<number>(0);
  const [yearQuotesNumber, setYearQuotesNumber] = useState<number>(0);

  const reQuoteHandle = (quote: any) => {
    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    const modelOptionData = quote?.model_option_ids
      ? JSON.parse(quote?.model_option_ids)
      : [];

    const _modelOptionData = modelOptionData?.map(
      (res: ModelOptionDataType) => {
        let _model = models.find(
          (model: Model) => model.ID === res.model_id.ID
        );

        return {
          ..._model,
          chose_options: res.option_ids,
          qty: res.qty,
          _id: generateRandomId(),
        };
      }
    );

    dispatch(setQuoteAdminModels(_modelOptionData));

    history("/quote_builder");
  };

  const fetchQuotes = async () => {
    try {
      const {
        data: quoteData,
        counts,
        lastMonthQuotesNumber,
        yearQuotesNumber,
      } = await getQuoteAll({ page: page + 1, pageSize });

      const transformedData = quoteData?.map((quote: any) => ({
        // activity: quote.activity,
        dealer:
          quote.dealer_id === "ADMIN"
            ? company?.company_name
            : quote?.dealer_name,
        contact:
          quote.dealer_id === "ADMIN"
            ? company?.contact_name
            : quote?.contact_name,
        quotes: (
          <ListItem
            disablePadding
            component={Link}
            to={`/quote/${quote.quoteId}`}
          >
            {quote.no}
          </ListItem>
        ),
        last_activity: moment(quote.createdAt).format("MM/DD/YYYY"),
        status: quote?.quote_status,
        edit: (
          <div>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setOpenModal(true);
                  setEditQuote(quote);
                }}
              >
                <EditCalendarIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Re-quote">
              <IconButton
                onClick={() => reQuoteHandle(quote)}
                disabled={quote.dealer_id !== "ADMIN"}
              >
                <ReplyIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      }));

      setQuotes(transformedData);

      setLastMonthQuotesNumber(lastMonthQuotesNumber);
      setYearQuotesNumber(yearQuotesNumber);
      setCount(counts);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!company || !models) return;

    fetchQuotes();
  }, [company, models, page, pageSize]);

  const permissionErrorMessage = () => {
    showSnackBar("Not allowed permission!", "error");
  };

  const handleChangeQuoteStatus = async () => {
    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    try {
      await updateQuote({
        ID: editQuote.quoteId,
        status: editQuote.quote_status,
      });
      showSnackBar("Changed this quote status.");
      setOpenModal(false);
      fetchQuotes();
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPageSize(Number(e.target.value));
  };

  const handleQuoteBuild = () => {
    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    dispatch(setQuoteAdminModels([]));
    history("/models_list");
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <StatCard
            value={lastMonthQuotesNumber}
            description="Quotes (Last 30 Days)"
          />
          <StatCard value={yearQuotesNumber} description="Quotes (This Year)" />
          <StatCard value={count} description="Quotes (All time)" />
          <Grid item xs={3}>
            <Card
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => handleQuoteBuild()}
              sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}
            >
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  +
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Build Quote
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input label="Search:" />
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={2} direction="row">
              <Button variant="contained">Apply</Button>
              <Button variant="outlined">Reset</Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <h3 style={{ textAlign: "center" }}>Recent Activity</h3>
        <DefaultTable
          headerData={headerData}
          rowsData={quotes}
          rowsPerPage={pageSize}
          page={page}
          count={count}
          handleChangePage={(
            e: React.ChangeEvent<HTMLInputElement>,
            page: number
          ) => handleChangePage(page)}
          handleChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(e)
          }
        />
      </Box>

      {editQuote && (
        <CustomDialog
          openModal={openModal}
          handleClose={() => setOpenModal(false)}
          handleAction={handleChangeQuoteStatus}
          buttonTitle="save"
          width="xs"
          title="Change"
        >
          <p />
          <SelectComponent
            menuItems={menuItems}
            // width="180px"
            label="Status"
            value={editQuote?.quote_status}
            onChange={(e) =>
              setEditQuote({ ...editQuote, quote_status: e.target.value })
            }
          />
        </CustomDialog>
      )}
    </>
  );
};
