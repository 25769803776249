import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Switch, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
// import ReactToPrint from "react-to-print";

import { PDFContent } from "../../../components/PDFContent";
import { getUserQuoteByID } from "../../../apis/quote";
import { getUserDealerByID, sendQuote } from "../../../apis/dealer";
import { useCompanyData } from "../../../hook/useCompanyData";
import { Loader } from "../../../components/Loader";
import { useModelData } from "../../../hook/useModelData";
import { useOptionData } from "../../../hook/useOptionData";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { getUserAll } from "../../../apis/admin";
import { fetchAdminEmails } from "../../../utils/quote";

export const UserPDF: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  const [quote, setQuote] = useState<any>({});
  const [dealer, setDealer] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean | false>(true);
  const [isSendingLoading, setIsSendingLoading] = useState<boolean | false>(
    false
  );
  const [ccEmails, setCCEmails] = useState<string[]>([]);
  const [priceType, setPriceType] = useState<string>("dealer_price");

  const { id } = useParams();
  const { showSnackBar } = useGlobalSnackBar();

  const { company } = useCompanyData("dealer");
  const { options } = useOptionData("dealer");
  const { models } = useModelData("dealer");

  const fetchAdmins = async () => {
    try {
      const adminsData = await getUserAll();
      setCCEmails(
        fetchAdminEmails(adminsData, JSON.parse(company.to_copy_admins))
      );
    } catch (error) {
      console.error("Failed to fetch company", error);
    }
  };

  useEffect(() => {
    if (!company) return;
    fetchAdmins();
  }, [company]);

  const getQuote = async () => {
    try {
      const quoteData = await getUserQuoteByID({ ID: id });
      setQuote(quoteData);
      try {
        const dealerData = await getUserDealerByID({ ID: quoteData.dealer_id });
        setDealer(dealerData);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getQuote();
  }, [id]);

  const handleExportWithPDF = async () => {
    try {
      var pdfContent: any = document.getElementById("PDFContent");
      var htmlContent = pdfContent.innerHTML;

      const response = await fetch(process.env.REACT_APP_API_URL + "/makePdf", {
        method: "POST",
        headers: {
          Accept: "application/pdf",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ htmlContent: htmlContent }),
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "quote.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const sendEmail = async () => {
    setIsSendingLoading(true);
    try {
      await sendQuote({
        ccEmails: ccEmails,
        to: dealer?.email,
        ID: quote?.ID,
      });
      showSnackBar("Sent email.");
      setIsSendingLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{ display: "flex" }}
        gap={2}
        alignItems="center"
        justifyContent="center"
        mb={2}
      >
        <Typography variant="h5" component="div">
          {priceType === "dealer_price" ? "Dealer Price" : priceType}:
        </Typography>
        <Tooltip title={priceType === "dealer_price" ? "Dealer Price" : "MSRP"}>
          <div>
            <Switch
              defaultChecked={priceType === "dealer_price" ? true : false}
              onChange={(e) => {
                if (e.target.checked) {
                  setPriceType("dealer_price");
                } else {
                  setPriceType("MSRP");
                }
              }}
            />
          </div>
        </Tooltip>
      </Grid>
      <div
        ref={componentRef}
        style={{
          width: "6.2in",
          minHeight: "8.5in",
          margin: "0 auto",
          position: "relative",
          border: "1px solid black",
        }}
        id="PDFContent"
      >
        <PDFContent
          quote={quote}
          companyInfo={{ ...company, ...dealer }}
          options={options}
          models={models}
          priceType={priceType}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
          width: "6.3in",
          margin: "0 auto",
        }}
      >
        {/* <ReactToPrint
          content={() => componentRef.current}
          trigger={() => <Button variant="contained">Print</Button>}
        /> */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleExportWithPDF}
        >
          Download
        </Button>
        <Button
          variant="contained"
          onClick={() => sendEmail()}
          disabled={isSendingLoading}
        >
          E-Mail
        </Button>
      </div>
    </Box>
  );
};
