import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableFooter, TablePagination } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

interface RowData<T> {
  [key: string]: T;
}

interface TableProps<T extends React.ReactNode> {
  headerData: string[];
  rowsData: RowData<T>[];
  rowsPerPage?: number;
  page?: number;
  count?: number;
  handleChangePage?: any;
  handleChangeRowsPerPage?: any;
  isTableFooter?: boolean;
}

const DefaultTable = <T extends React.ReactNode>({
  headerData,
  rowsData,
  rowsPerPage = 10,
  page = 0,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
  isTableFooter = true,
}: TableProps<T>) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headerData.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {Object.values(row).map((value, innerIndex) => (
                <TableCell key={innerIndex}>{value}</TableCell>
              ))}
            </TableRow>
          ))}
          {!rowsData.length && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell colSpan={5} sx={{ textAlign: "center", fontSize: 24, padding: 10 }}>
                <i>No Data</i>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {isTableFooter && rowsData.length > 0 && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                colSpan={headerData?.length}
                count={count || rowsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default DefaultTable;
