import React, { ReactNode, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import DefaultTable from "../../../components/Table/DefaultTable";
import { TabProps } from "../../../constants/Interface";
import { getAll, invite, updateAdmin } from "../../../apis/admin";
import { CustomModal } from "../../../components/modal/CustomModal";
import { SelectComponent } from "../../../components/Select";
import { permissions } from "../../../constants/Constant";
import { Input } from "../../../components/Input";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { Loader } from "../../../components/Loader";
import { CustomDialog } from "../../../components/modal/CustomDialog";
import { MenuItemData } from "../../../types/global.types";
import { usePermissionHook } from "../../../hook/usePermissionHook";

interface TRowData<T> {
  [key: string]: T;
}

const headerData: string[] = ["Name", "Email", "Permission", ""];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const menuItems: MenuItemData[] = [
  { value: "Administrator", label: "Administrator" },
  { value: "Sales", label: "Sales" },
  { value: "Read-Only", label: "Read-Only" },
];

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const UsersTab: React.FC<TabProps> = ({ value }) => {
  const [admins, setAdmins] = useState<TRowData<ReactNode>[]>([]);
  const [emailError, setEmailError] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean | false>(true);
  const [editAdmin, setEditAdmin] = useState<any>();

  const { showSnackBar } = useGlobalSnackBar();
  const { permissionReadOnly } = usePermissionHook();

  const fetchAdmins = async () => {
    try {
      const adminsData = await getAll();

      const transformedData = adminsData.map((admin: any) => ({
        name: `${admin.first_name} ${admin.last_name}`,
        email: admin.email,
        permission: admin.permission,
        edit: (
          <div>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setEditAdmin(admin);
                  setOpenEditModal(true);
                }}
              >
                <EditCalendarIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      }));

      setAdmins(transformedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAdmins();
  }, []);

  const permissionErrorMessage = () => {
    showSnackBar("Not allowed permission!", "error");
  };

  const handleEmailChange = (e: any) => {
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    const formData = new FormData(e.target as HTMLFormElement);

    // Convert FormData entries to an object
    const formObject = Object.fromEntries(formData.entries());
    const res = await invite(formObject);
    if (res) {
      setOpenModal(false);
      showSnackBar("Sent invitation!");
    }

    // (e.target as HTMLFormElement).reset();
  };

  const handleChangeAdminRole = async () => {
    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    try {
      await updateAdmin(editAdmin);
      showSnackBar("Changed this admin permission.");
      setOpenEditModal(false);
      fetchAdmins();
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <CustomTabPanel value={value} index={0}>
      <Stack spacing={2} direction="row" sx={{ mb: 1.5 }}>
        <Button variant="contained" onClick={() => setOpenModal(true)}>
          Invite User
        </Button>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <DefaultTable
          headerData={headerData}
          rowsData={admins}
          isTableFooter={false}
          handleChangePage={() => undefined}
          handleChangeRowsPerPage={() => undefined}
        />
      </Box>
      <CustomModal
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
        width={500}
        handleSubmit={handleSubmit}
      >
        <Typography variant="h5" noWrap align="center" sx={{ mb: 2 }}>
          Invite a user
        </Typography>
        <Input label="First Name" name="first_name" sx={{ mb: 2 }} />
        <Input label="Last Name" name="last_name" sx={{ mb: 2 }} />
        <Input
          label="E-mail Address"
          name="email"
          required
          error={emailError}
          onChange={handleEmailChange}
          sx={{ mb: 2 }}
        />
        <SelectComponent
          label="Permission"
          name="permission"
          menuItems={permissions}
        />
        <Typography align="center" sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" type="submit">
            Send Invitation
          </Button>
        </Typography>
      </CustomModal>
      {editAdmin && (
        <CustomDialog
          openModal={openEditModal}
          handleClose={() => setOpenEditModal(false)}
          handleAction={handleChangeAdminRole}
          buttonTitle="save"
          width="xs"
          title="Permisson Change"
        >
          <p />
          <SelectComponent
            menuItems={menuItems}
            label="Status"
            value={editAdmin?.permission}
            onChange={(e) =>
              setEditAdmin({ ...editAdmin, permission: e.target.value })
            }
          />
        </CustomDialog>
      )}
    </CustomTabPanel>
  );
};
