import { createStore, combineReducers } from "redux";
import { userAuthReducer } from "./reducers/userAuthReducer";
import { adminAuthReducer } from "./reducers/adminAuthReducer";

const rootReducer = combineReducers({
  userAuth: userAuthReducer,
  adminAuth: adminAuthReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer);

export default store;
