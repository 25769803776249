import React, { ReactNode, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import BasicPagination from "../../../components/BasicPagination";
import { getModels } from "../../../apis/model";
import { useOptionData } from "../../../hook/useOptionData";
import {
  filteredChipOptions,
  getHoverImage,
  Option,
} from "../../../utils/option";
import { ITEMS_PER_PAGE } from "../../../constants/Constant";
import { Loader } from "../../../components/Loader";
import { Carousel } from "../../../components/Carousel";

const useStyles = makeStyles(() => ({
  firstContent: {
    textAlign: "right",
  },
  CardContent: {
    width: "30%",
  },
  root: {
    display: "flex",
    gap: "10px",
  },
  rootBottom: {
    alignItems: "baseline",
    paddingLeft: "20px",
  },
  buildBtn: {
    marginTop: "calc(100% - 100px) !important",
  },
  modelTitle: {
    cursor: "pointer",
  },
  hoveredImageWrapper: {
    position: "absolute",
    zIndex: 10000,
  },
  chipWrapper: {
    marginRight: "8px",
    cursor: "pointer",
  },
  hoveredImage: {
    width: "200px",
    height: "100px",
    transition: "opacity 0.3s ease-in-out",
    opacity: 1,
    objectFit: "cover",
  },
  hidden: {
    opacity: 0,
  },
}));

interface TRowData<T> {
  [key: string]: T;
}

export const ModelLists: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { options } = useOptionData();

  const [models, setModels] = useState<TRowData<ReactNode>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean | false>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentModelData, setCurrentModelData] = useState<
    TRowData<ReactNode>[]
  >([]);
  const [pageNumber, setPageNumber] = useState<number>(ITEMS_PER_PAGE);
  const [hoveredOption, setHoveredOption] = useState<Option | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (
    option: Option,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setMousePosition({ x: event.pageX, y: event.pageY });
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setMousePosition({ x: event.pageX, y: event.pageY });
  };

  const fetchModels = async () => {
    try {
      const modelData = await getModels();

      const transformedData = modelData.map((model: any) => ({
        id: model.ID,
        imageUrl: model.images
          ? JSON.parse(model.images)?.filter((item: any) => item !== null)
          : [],
        title: model.model_name,
        dimensions: {
          height: model.demention_height,
          width: model.demention_width,
          depth: model.demention_depth,
        },
        rackSpaces: model.demention_rack_spaces,
        partNumber: model.part_number,
        msrp: model.MSRP,
        dealerPrice: model.dealer_price,
        options: filteredChipOptions(
          options,
          JSON.parse(model.applicable_option_ids)
        )?.map((res) => (
          <Chip
            key={res?.ID}
            label={res?.option_name}
            variant="outlined"
            onMouseEnter={(e) => handleMouseEnter(res, e)}
            onMouseLeave={handleMouseLeave}
            className={classes.chipWrapper}
            onMouseMove={handleMouseMove}
          />
        )),
      }));

      setModels(transformedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    fetchModels();
  }, [options]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const currentModelData = models?.slice(
      startIndex,
      startIndex + ITEMS_PER_PAGE
    );
    setCurrentModelData(currentModelData);
  }, [models, currentPage, pageNumber]);

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          sx={{
            pr: { xl: "20%", lg: "5%", sm: "10px", xs: "10px" },
            pl: { xl: "20%", lg: "5%", sm: "10px", xs: "10px" },
          }}
        >
          {currentModelData.map((rack: any) => (
            <Grid item xs={12} sm={12} md={12} key={rack.id} sx={{ mb: 3 }}>
              <Card>
                <div className={classes.root}>
                  <CardContent
                    className={`${classes.firstContent} ${classes.CardContent}`}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={classes.modelTitle}
                      // onClick={() => navigate(`/_models/${rack.id}/view`)}
                    >
                      {rack.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Dimensions
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Height: {rack.dimensions.height}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Width: {rack.dimensions.width}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Depth: {rack.dimensions.depth}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Rack Spaces: {rack.rackSpaces}
                    </Typography>
                  </CardContent>
                  <Carousel images={rack.imageUrl} />
                  <CardContent className={classes.CardContent}>
                    <Typography variant="body2">
                      Part # {rack.partNumber}
                    </Typography>
                    <Typography variant="body2">MSRP: {rack.msrp}</Typography>
                    <Typography variant="body2">
                      Dealer Price: {rack.dealerPrice}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate(`/models/${rack.id}/view`)}
                      className={classes.buildBtn}
                    >
                      Build Quote
                    </Button>
                  </CardContent>
                </div>
                <div className={`${classes.root} ${classes.rootBottom}`}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className={classes.modelTitle}
                  >
                    Applicable options:
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {rack.options.length ? rack.options : <i>Empty</i>}
                  </Typography>

                  {hoveredOption && (
                    <Box
                      sx={{
                        top: mousePosition.y + 10, // Offset by 10 pixels to avoid cursor overlap
                        left: mousePosition.x + 10,
                        pointerEvents: "none", // Ensures the image does not capture mouse events
                      }}
                      className={classes.hoveredImageWrapper}
                    >
                      {JSON.parse(hoveredOption.images)?.[0] ? (
                        <img
                          src={getHoverImage(hoveredOption.images) || ""}
                          className={`${classes.hoveredImage} ${
                            hoveredOption ? "" : classes.hidden
                          }`}
                        />
                      ) : (
                        <i>No images</i>
                      )}
                    </Box>
                  )}
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Grid
        container
        sx={{
          pr: { xl: "20%", lg: "5%", sm: "10px", xs: "10px" },
          pl: { xl: "20%", lg: "5%", sm: "10px", xs: "10px" },
          justifyContent: "end",
        }}
      >
        <BasicPagination
          pageCount={Math.ceil(models?.length / pageNumber)}
          page={currentPage}
          setPage={setCurrentPage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </Grid>
    </>
  );
};
