import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store/store";

export const usePermissionHook = () => {
  const currentUser = useSelector((state: RootState) => state.adminAuth.admin);

  const permissionReadOnly = useMemo(() => {
    return currentUser?.permission === "Read-Only";
  }, [currentUser]);

  return { permissionReadOnly };
};
