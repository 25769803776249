import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";

import "./styles.css";

interface CarouselProps {
  images: string[];
}

export const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const loopEnabled = useMemo(() => {
    return images.length > 2;
  }, [images]);

  if (images.length === 0)
    return (
      <Typography variant="h6" component="div" className="noImage">
        <i>No images</i>
      </Typography>
    );

  return (
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={"auto"}
      loop={loopEnabled}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={true}
      modules={[EffectCoverflow, Pagination]}
      className="mySwiper"
    >
      {images?.map((image) => (
        <SwiperSlide
          key={image}
          style={{
            backgroundImage: `url(${`${process.env.REACT_APP_COMPANY_LOGO_URL}/${image}`})`,
          }}
        />
      ))}
    </Swiper>
  );
};
