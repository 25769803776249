import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { AdminLayout } from "./pages/admin/AdminLayout";
import { AdminLoginPage } from "./pages/admin/auth/AdminLoginPage";
import { AdminForgotPasswordPage } from "./pages/admin/auth/AdminForgotPasswordPage";
import { Dashboard } from "./pages/admin/Dashboard";
import { Models } from "./pages/admin/Models";
import { Options } from "./pages/admin/Options";
import { Dealers } from "./pages/admin/Dealers";
import { Quotes } from "./pages/admin/Quotes";
import { Settings } from "./pages/admin/Settings";
import { Faq } from "./pages/admin/Faq";
import { ModelsView } from "./pages/admin/Models/ModelsView";
import { OptionsView } from "./pages/admin/Options/OptionsView";
import { Categories } from "./pages/admin/Options/Categories";
import { DealerView } from "./pages/admin/Dealers/DealerView";
import { AdminInvite } from "./pages/admin/auth/AdminInvite";
import { QuoteBuilder } from "./pages/admin/Quotes/QuoteBuilder";
import { PDF } from "./pages/admin/Quotes/Pdf";
import { ModelLists } from "./pages/admin/Models/ModelLists";
import { ModelPreview } from "./pages/admin/Models/ModelPreview";
import { QuoteCustomization } from "./pages/admin/Models/Customization";

import { UserLayout } from "./pages/user/UserLayout";
import { UserLoginPage } from "./pages/user/auth/UserLoginPage";
import { UserDashboard } from "./pages/user/Dashboard";
import { UserModels } from "./pages/user/Models";
import { UserQuotes } from "./pages/user/Quotes";
import { UserSettings } from "./pages/user/Settings";
import { UserFaq } from "./pages/user/Faq";
import { UserModelsView } from "./pages/user/Models/ModelsView";
import { UserQuoteCustomization } from "./pages/user/Models/Customization";
import { UserQuoteBuilder } from "./pages/user/QuoteBuilder";
import { UserPDF } from "./pages/user/Quotes/Pdf";
import { UserForgotPasswordPage } from "./pages/user/auth/UserForgotPasswordPage";
import { DealerInvite } from "./pages/user/auth/DealerInvite";
import { DealerForgotPasswordPage } from "./pages/user/auth/DealerForgotPasswordPage";
import { ForgotPassPage } from "./pages/admin/auth/ForgotPassPage";

const ProtectedAdminRoutes = () => {
	const localStorageToken = localStorage.getItem("admin_access_token");

	return localStorageToken ? <Outlet /> : <Navigate to="/admin/login"  replace />;
};

const ProtectedUserRoutes = () => {
	const localStorageToken = localStorage.getItem("user_access_token");

	return localStorageToken ? <Outlet /> : <Navigate to="/user/login"  replace />;
};

export const router = createBrowserRouter(
  [
    {
      path: "/user/login",
      element: <UserLoginPage />,
    },
    {
      path: "/user/forgot-password",
      element: <UserForgotPasswordPage />,
    },
    {
      path: "/dealer-invite",
      element: <DealerInvite />,
    },
    {
      path: "/dealer-forgot-pass",
      element: <DealerForgotPasswordPage />,
    },
    {
      element: <ProtectedUserRoutes />,
      children: [
        {
          path: "/",
          element: <UserLayout children={<UserDashboard />} title='Dashboard' />,
        },
      ],
    },
    {
      element: <ProtectedUserRoutes />,
      children: [
        {
          path: "/_models",
          element: <UserLayout children={<UserModels />} title='Models' />,
        },
      ],
    },
    {
      element: <ProtectedUserRoutes />,
      children: [
        {
          path: "/_models/:ID/view",
          element: <UserLayout children={<UserModelsView />} title='Models' />,
        },
      ],
    },
    {
      element: <ProtectedUserRoutes />,
      children: [
        {
          path: "/_models/:ID/customize",
          element: <UserLayout children={<UserQuoteCustomization />} title='Quote & Customization' />,
        },
      ],
    },
    {
      element: <ProtectedUserRoutes />,
      children: [
        {
          path: "/_quotes",
          element: <UserLayout children={<UserQuotes />} title='Quotes' />,
        },
      ],
    },
    {
      element: <ProtectedUserRoutes />,
      children: [
        {
          path: "/_quote/:id",
          element: <UserLayout children={<UserPDF />} title='Quote' />,
        },
      ],
    },
    {
      element: <ProtectedUserRoutes />,
      children: [
        {
          path: "/_quote_builder",
          element: <UserLayout children={<UserQuoteBuilder />} title='Quote Builder' />,
        },
      ],
    },
    {
      element: <ProtectedUserRoutes />,
      children: [
        {
          path: "/_settings",
          element: <UserLayout children={<UserSettings />} title='Settings' />,
        },
      ],
    },
    {
      element: <ProtectedUserRoutes />,
      children: [
        {
          path: "/_faq",
          element: <UserLayout children={<UserFaq />} title='FAQ' />,
        },
      ],
    },





    {
      path: "/admin/login",
      element: <AdminLoginPage />,
    },
    {
      path: "/admin/forgot-password",
      element: <AdminForgotPasswordPage />,
    },
    {
      path: "/admin-forgot-pass",
      element: <ForgotPassPage />,
    },
    {
      path: "/admin-invite",
      element: <AdminInvite />,
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/dashboard",
          element: <AdminLayout children={<Dashboard />} title='Dashboard' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/models/:ID",
          element: <AdminLayout children={<ModelsView />} title='Models' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/models_list",
          element: <AdminLayout children={<ModelLists />} title='Builder Quote' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/models/:ID/view",
          element: <AdminLayout children={<ModelPreview />} title='Model Preview' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/models/:ID/customize",
          element: <AdminLayout children={<QuoteCustomization />} title='Model Customize' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/models/create",
          element: <AdminLayout children={<ModelsView />} title='New model' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/models/",
          element: <AdminLayout children={<Models />} title='Models' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/options/:ID",
          element: <AdminLayout children={<OptionsView />} title='Options' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/options",
          element: <AdminLayout children={<Options />} title='Options' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/options/create",
          element: <AdminLayout children={<OptionsView />} title='New option' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/options/categories",
          element: <AdminLayout children={<Categories />} title='Manage Categories' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/dealers",
          element: <AdminLayout children={<Dealers />} title='Dealers' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/dealers/:ID",
          element: <AdminLayout children={<DealerView />} title='Dealers' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/quotes",
          element: <AdminLayout children={<Quotes />} title='Quotes' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/quote_builder",
          element: <AdminLayout children={<QuoteBuilder />} title='Quote Builder' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/quote/:id",
          element: <AdminLayout children={<PDF />} title='Quote' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/settings",
          element: <AdminLayout children={<Settings />} title='Settings' />,
        },
      ],
    },
    {
      element: <ProtectedAdminRoutes />,
      children: [
        {
          path: "/faq",
          element: <AdminLayout children={<Faq />} title='FAQ' />,
        },
      ],
    },
  ],
  { basename: "/" }
);

