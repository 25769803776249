import React, { ReactNode, useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";

import DefaultTable from "../../../components/Table/DefaultTable";
import { deleteModel, getSearchModels } from "../../../apis/model";
import { CustomDialog } from "../../../components/modal/CustomDialog";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { useOptionData } from "../../../hook/useOptionData";
import { filteredOptions } from "../../../utils/option";
import { Loader } from "../../../components/Loader";
import { usePermissionHook } from "../../../hook/usePermissionHook";

interface TRowData<T> {
  [key: string]: T;
}

const headerData: string[] = ["Model", "Options", "MSRP", "Dealer Price", ""];

export const Models: React.FC = () => {
  const history = useNavigate();

  const [models, setModels] = useState<TRowData<ReactNode>[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [modelId, setModelId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean | false>(true);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);

  const { options } = useOptionData();
  const { showSnackBar } = useGlobalSnackBar();
  const { permissionReadOnly } = usePermissionHook();

  const fetchModels = async () => {
    try {
      const { data: modelData, counts } = await getSearchModels({
        page: page + 1,
        pageSize,
      });

      const transformedData = modelData.map((model: any) => ({
        model: (
          <ListItem disablePadding component={Link} to={`/models/${model.ID}`}>
            {model.model_name}
          </ListItem>
        ),
        options: filteredOptions(
          options,
          JSON.parse(model.applicable_option_ids)
        ),
        msrp: model.MSRP,
        dealer_price: model.dealer_price,
        edit: (
          <div>
            <Tooltip title="Edit">
              <IconButton onClick={() => history(`/models/${model.ID}`)}>
                <EditCalendarIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  if (!permissionReadOnly) {
                    setModelId(model.ID);
                    setOpenDeleteModal(true);
                  } else {
                    permissionErrorMessage();
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      }));

      setModels(transformedData);
      setCount(counts);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (options) {
      fetchModels();
    }
  }, [options, page, pageSize]);

  const onDelete = async () => {
    try {
      await deleteModel({ ID: modelId || "" });
      showSnackBar("Delete this model");
      fetchModels();
      setOpenDeleteModal(false);
    } catch (err) {
      showSnackBar("Error", "error");
    }
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPageSize(Number(e.target.value));
  };

  const permissionErrorMessage = () => {
    showSnackBar("Not allowed permission!", "error");
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={2}>
            <Card style={{ textAlign: "center" }}>
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  {count}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Base Models
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => {
                if (permissionReadOnly) {
                  permissionErrorMessage();
                  return;
                }
                history("/models/create");
              }}
              sx={{ textAlign: "center" }}
            >
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  +
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Add Model
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <DefaultTable
          headerData={headerData}
          rowsData={models}
          rowsPerPage={pageSize}
          page={page}
          count={count}
          handleChangePage={(
            e: React.ChangeEvent<HTMLInputElement>,
            page: number
          ) => handleChangePage(page)}
          handleChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(e)
          }
        />
      </Box>

      <CustomDialog
        title="Are you sure you want to delete this model?"
        openModal={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleAction={onDelete}
        buttonTitle={"Yes"}
        children={undefined}
      />
    </>
  );
};
