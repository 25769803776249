import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import HomeIcon from "@mui/icons-material/Home";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import SettingsIcon from "@mui/icons-material/Settings";
import QuizIcon from "@mui/icons-material/Quiz";
import DescriptionIcon from '@mui/icons-material/Description';

import { SnackbarProvider } from "../SnackbarContext";
import { Header } from "../../components/layout/Header";
import { UserAuthService } from "../../services/UserAuthService";
import { useCompanyData } from "../../hook/useCompanyData";
import { Sidebar } from "../../components/layout/Sidebar";

interface UserLayoutProps {
  children: ReactNode;
  title: string;
}

const useStyles = makeStyles(() => ({
  listItem: {
    "& svg": {
      color: "white",
    },
  },
  listColor: {
    color: "var(--kendo-color-app-surface)",
  },
  activeListItem: {
    background: "var(--kendo-color-base-subtle-hover)",
    "& svg": {
      color: "var(--kendo-color-base-on-surface)",
    },
    "&>div>div>span": {
      color: "var(--kendo-color-base-on-surface)",
    },
  },
}));

export const UserLayout: React.FC<UserLayoutProps> = ({ children, title }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const { company } = useCompanyData("dealer");

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const logout = useCallback(() => {
    UserAuthService.logout(dispatch);
    navigate("/user/login");
  }, [dispatch, navigate]);

  const drawer = useMemo(() => {
    return (
      <div>
        <Toolbar />
        <Divider />
        <Avatar
          src={
            typeof company?.company_logo === "string"
              ? `${process.env.REACT_APP_COMPANY_LOGO_URL}/${company?.company_logo}`
              : undefined
          }
          sx={{
            display: "block",
            width: "100px",
            height: "100px",
            m: "0 auto",
            mt: 2,
            mb: 1,
          }}
        />
        <List>
          <ListItem
            disablePadding
            component={Link}
            to="/"
            className={location.pathname === "/" ? classes.activeListItem : ""}
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/_models"
            className={
              location.pathname.includes("/_models")
                ? classes.activeListItem
                : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <ModelTrainingIcon />
              </ListItemIcon>
              <ListItemText primary="Models" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/_quotes"
            className={
              location.pathname.includes("/_quote")
                ? classes.activeListItem
                : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Quotes" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/_settings"
            className={
              location.pathname === "/_settings" ? classes.activeListItem : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/_faq"
            className={
              location.pathname === "/_faq" ? classes.activeListItem : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <QuizIcon />
              </ListItemIcon>
              <ListItemText primary="FAQ" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    );
  }, [company, classes, location]);

  return (
    <SnackbarProvider>
      <Header
        title={title}
        handleDrawerToggle={handleDrawerToggle}
        logout={logout}
      />
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawer={drawer}
      />
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          padding: "80px 10px 0",
          paddingLeft: { md: "250px" },
        }}
      >
        {children}
      </Box>
    </SnackbarProvider>
  );
};
