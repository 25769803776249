import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";

interface SelectProps {
  openModal: boolean;
  handleClose: () => void;
  handleAction?: () => void;
  children: any;
  buttonTitle: string;
  width?: DialogProps['maxWidth'];
  title: string;
}

export const CustomDialog: React.FC<SelectProps> = ({
  openModal,
  handleClose,
  handleAction,
  children,
  buttonTitle,
  width,
  title,
}) => {
  return (
    <Dialog open={openModal} onClose={handleClose} maxWidth={width} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAction}>{buttonTitle}</Button>
      </DialogActions>
    </Dialog>
  );
};
