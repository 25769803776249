export type Option = {
  ID: string;
  part_number: string;
  option_name: string;
  applicable_model_ids: string;
  uncompatible_option_ids: string;
  MSRP: number | null;
  dealer_price: number | 0;
  demention_height: number | null;
  demention_width: number | null;
  demention_depth: number | null;
  demention_rack_spaces: number | null;
  images: string;
  description: string | null;
  is_category: boolean;
  parent_id: string;
  isAdded?: boolean;
  disabled?: boolean;
  uncompatible_ids?: string[];
};

export const filteredOptions = (options: Option[], idsToMatch: any) => {
  return options
    .filter((option: Option) => idsToMatch.includes(option.ID))
    ?.map((option: Option) => option.option_name)
    .join(", ");
};

export const filteredChipOptions = (options: Option[], idsToMatch: any) => {
  return options.filter((option: Option) => idsToMatch.includes(option.ID));
};

export const getHoverImage = (images: string) => {
  if (JSON.parse(images)?.[0]) {
    return `${process.env.REACT_APP_COMPANY_LOGO_URL}/${
      JSON.parse(images)?.[0]
    }`;
  }

  return null;
};

export const transformOptions = (A: any, forOrder = false) => {
  let idMap: any = {};
  let rootItems: any = [];

  // First pass: create a map of items by ID
  A.forEach((item: any) => {
    idMap[item.ID] = {
      ...(forOrder && item),
      text: item.option_name,
      id: item.ID,
      items: [],
    };
  });

  // Second pass: link children to their parents
  A.forEach((item: any) => {
    if (item.parent_id) {
      idMap[item.parent_id]?.items.push(idMap[item.ID]);
    } else {
      rootItems.push(idMap[item.ID]);
    }
  });

  // Sort the items by the "order" field
  function sortItems(items: any) {
    items.sort((a: any, b: any) => {
      let aOrder = A.find((item: any) => item.ID === a.id).order;
      let bOrder = A.find((item: any) => item.ID === b.id).order;
      return aOrder - bOrder;
    });

    items.forEach((item: any) => sortItems(item.items));
  }

  sortItems(rootItems);

  return rootItems;
};

export const transformOriginalOptions = (A: any, forOrder = false) => {
  let result: any = [];

  function traverse(items: any, parentId = "") {
    let orderCounter = 0;
    items.forEach((item: any) => {
      const hasChildren = item.items.length > 0;
      result.push({
        ...(forOrder && item),
        ID: item.id,
        option_name: item.text,
        parent_id: parentId,
        order: orderCounter++,
        children: hasChildren,
      });
      if (hasChildren) {
        traverse(item.items, item.id);
      }
    });
  }

  traverse(A);

  return result;
};
