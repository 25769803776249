import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

interface SnackBarProps {
  message: string;
  open: boolean;
  handleClose?: any;
  duration?: number;
  messageType?: any;
  vertical?: "top" | "bottom";
  horizontal?: "left" | "center" | "right";
}

export const SnackBar: React.FC<SnackBarProps> = ({
  message,
  open,
  handleClose,
  duration = 1500,
  messageType = "success",
  vertical = "top",
  horizontal = "right",
}) => {
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleClose}
          severity={messageType}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
