import { Reducer } from "redux";
import { UserAuthActionType, UserAuthActionTypes } from "../actions";
import { jwtDecode } from "jwt-decode";

export interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  error: string | null;
  user: any;
  quoteModels: any;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: localStorage.getItem("user_access_token") || null,
  error: null,
  user: localStorage.getItem("user_access_token")
    ? jwtDecode(localStorage.getItem("user_access_token") || "")
    : null,
  quoteModels: [],
};

export const userAuthReducer: Reducer<AuthState, UserAuthActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UserAuthActionType.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        error: null,
        user: jwtDecode(action.payload.token),
      };
    case UserAuthActionType.LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        error: action.payload.error,
        user: null,
      };
    case UserAuthActionType.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        error: null,
        admin: null,
      };

    // regarding quote store
    case UserAuthActionType.SET_QUOTE_MODELS:
      return {
        ...state,
        quoteModels: action.payload.quoteModels,
      };

    default:
      return state;
  }
};
