import * as React from "react";

import {
  TreeView,
  TreeViewDragClue,
  processTreeViewItems,
  moveTreeViewItem,
  TreeViewDragAnalyzer,
  TreeViewItemDragOverEvent,
  TreeViewItemDragEndEvent,
  TreeViewCheckChangeEvent,
} from "@progress/kendo-react-treeview";

interface TreeViewDataItem {
  text: string;
  id: string;
  is_category?: boolean;
  expanded?: boolean;
  checked?: boolean;
  selected?: boolean;
  items?: TreeViewDataItem[];
}

interface TreeDataProps {
  tree: TreeViewDataItem[];
  setTree: any;
}

function getSiblings(itemIndex: string, data: TreeViewDataItem[]) {
  let result = data;

  const indices = itemIndex.split(SEPARATOR).map((index) => Number(index));
  for (let i = 0; i < indices.length - 1; i++) {
    result = result[indices[i]].items || [];
  }

  return result;
}

function getItemByHierarchicalIndex(
  hierarchicalIndex: string,
  data: TreeViewDataItem[]
): TreeViewDataItem | null {
  const indices = hierarchicalIndex.split(SEPARATOR).map(Number);
  let currentItem: TreeViewDataItem | null = null;

  indices.forEach((index) => {
    if (currentItem) {
      currentItem = currentItem.items ? currentItem.items[index] : null;
    } else {
      currentItem = data[index];
    }
  });

  return currentItem;
}

const SEPARATOR = "_";

export const DraggableTreeView: React.FC<TreeDataProps> = ({
  tree,
  setTree,
}) => {
  const dragClue = React.useRef<any>();
  const dragOverCnt = React.useRef<number>(0);
  const isDragDrop = React.useRef<boolean>(false);
  const [expand, setExpand] = React.useState({ ids: [], idField: "id" });
  const [selected, setSelected] = React.useState({ ids: [], idField: "text" });

  const getClueClassName = (event: any) => {
    const eventAnalyzer: any = new TreeViewDragAnalyzer(event).init();
    const { itemHierarchicalIndex: itemIndex } = eventAnalyzer.destinationMeta;
    const parentItem: any = getItemByHierarchicalIndex(
      eventAnalyzer.destItemId,
      tree
    );
    const draggedItem: any = getItemByHierarchicalIndex(
      event.itemHierarchicalIndex,
      tree
    );

    if (eventAnalyzer.isDropAllowed) {
      const dropOperation = eventAnalyzer.getDropOperation();
      const draggedIsCategory = draggedItem?.is_category;
      const parentIsCategory = parentItem?.is_category;

      if (!draggedIsCategory && parentIsCategory) {
        // Allow move if dragged item is not a category and parent is a category
        switch (dropOperation) {
          case "child":
            return "k-i-plus";
          case "before":
            return itemIndex === "0" || itemIndex.endsWith(`${SEPARATOR}0`)
              ? "k-i-insert-up"
              : "k-i-insert-middle";
          case "after":
            const siblings = getSiblings(itemIndex, tree);
            const lastIndex = Number(itemIndex.split(SEPARATOR).pop());
            return lastIndex < siblings.length - 1
              ? "k-i-insert-middle"
              : "k-i-insert-down";
          default:
            break;
        }
      } else if (parentIsCategory && draggedIsCategory) {
        // Allow reordering within the same category
        switch (dropOperation) {
          case "before":
            return itemIndex === "0" || itemIndex.endsWith(`${SEPARATOR}0`)
              ? "k-i-insert-up"
              : "k-i-insert-middle";
          case "after":
            const siblings = getSiblings(itemIndex, tree);
            const lastIndex = Number(itemIndex.split(SEPARATOR).pop());
            return lastIndex < siblings.length - 1
              ? "k-i-insert-middle"
              : "k-i-insert-down";
          default:
            break;
        }
      } else if (!parentIsCategory && !draggedIsCategory) {
        // Allow reordering of non-category items within the same parent
        switch (dropOperation) {
          case "before":
            return itemIndex === "0" || itemIndex.endsWith(`${SEPARATOR}0`)
              ? "k-i-insert-up"
              : "k-i-insert-middle";
          case "after":
            const siblings = getSiblings(itemIndex, tree);
            const lastIndex = Number(itemIndex.split(SEPARATOR).pop());
            return lastIndex < siblings.length - 1
              ? "k-i-insert-middle"
              : "k-i-insert-down";
          default:
            break;
        }
      }
    }

    return "k-i-cancel";
  };

  const onItemDragOver = (event: TreeViewItemDragOverEvent) => {
    dragOverCnt.current++;
    dragClue.current.show(
      event.pageY + 10,
      event.pageX,
      event.item.text,
      getClueClassName(event)
    );
  };

  const onItemDragEnd = (event: TreeViewItemDragEndEvent) => {
    isDragDrop.current = dragOverCnt.current > 0;
    dragOverCnt.current = 0;
    dragClue.current.hide();

    const eventAnalyzer: any = new TreeViewDragAnalyzer(event).init();
    const draggedItem: any = getItemByHierarchicalIndex(
      event.itemHierarchicalIndex,
      tree
    );
    const destinationItem: any = getItemByHierarchicalIndex(
      eventAnalyzer.destItemId,
      tree
    );

    const dropOperation = eventAnalyzer.getDropOperation();

    // Check if the dragged item is not a category and the destination item is a category
    const draggedIsCategory = draggedItem?.is_category;
    const destinationIsCategory = destinationItem?.is_category;

    if (!draggedIsCategory && destinationIsCategory) {
      // Allow the move if the dragged item is not a category and the destination is a category
      if (eventAnalyzer.isDropAllowed) {
        const updatedTree = moveTreeViewItem(
          event.itemHierarchicalIndex,
          tree,
          dropOperation || "child",
          eventAnalyzer.destinationMeta.itemHierarchicalIndex
        );
        setTree(updatedTree);
      }
    } else if (destinationIsCategory && draggedIsCategory) {
      // Allow reordering within the same category
      if (dropOperation === "before" || dropOperation === "after") {
        if (eventAnalyzer.isDropAllowed) {
          const updatedTree = moveTreeViewItem(
            event.itemHierarchicalIndex,
            tree,
            dropOperation,
            eventAnalyzer.destinationMeta.itemHierarchicalIndex
          );
          setTree(updatedTree);
        }
      }
    } else if (!destinationIsCategory && !draggedIsCategory) {
      // Allow reordering of non-category items
      if (dropOperation === "before" || dropOperation === "after") {
        if (eventAnalyzer.isDropAllowed) {
          const updatedTree = moveTreeViewItem(
            event.itemHierarchicalIndex,
            tree,
            dropOperation,
            eventAnalyzer.destinationMeta.itemHierarchicalIndex
          );
          setTree(updatedTree);
        }
      }
    }
  };

  const onExpandChange = (event: TreeViewCheckChangeEvent) => {
    let ids: any = expand.ids.slice();
    const index = ids.indexOf(event.item.id);

    index === -1 ? ids.push(event.item.id) : ids.splice(index, 1);
    setExpand({ ids, idField: "id" });
  };

  return (
    <div>
      <TreeView
        draggable={true}
        onItemDragOver={onItemDragOver}
        onItemDragEnd={onItemDragEnd}
        data={processTreeViewItems(tree, {
          expand: expand,
          select: selected,
        })}
        expandIcons={true}
        onExpandChange={onExpandChange}
        // onItemClick={onItemClick}
      />
      <TreeViewDragClue ref={dragClue} />
    </div>
  );
};
