import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import { FAQDataType } from "../../types/global.types";

interface Model {
  ID: string;
  model_name: string;
  MSRP: number;
  dealer_price: number;
  demention_height: number | null;
  demention_width: number | null;
  demention_depth: number | null;
  demention_rack_spaces: number | null;
  images: string;
  applicable_option_ids: string;
  description: string;
}

const models: Model[] = [
  {
    ID: "ad791c18-9d6b-4e73-9692-614877464cf6",
    model_name: "Second Model",
    MSRP: 23,
    dealer_price: 23,
    demention_height: null,
    demention_width: null,
    demention_depth: null,
    demention_rack_spaces: null,
    images:
      '["fbd0748e9ffda7794d46e10e5d16165e7.jpeg","21c9e9e3fcc38ee8764f9adf78735105e.png","1bb259de581c70c97e234bbad83b22f1.jpg"]',
    applicable_option_ids:
      '["432bd2ca-a189-41a3-851b-9425f5d9d283","b09b3e55-5135-46dc-a871-1ef8b25e2e71"]',
    description:
      "admin_api_helper.ts:22 Uncaught (in promise) AxiosError {message: 'Request failed with status code 404', name: 'AxiosError', code: 'ERR_BAD_REQUEST', config: {...}, request: XMLHttpRequest, ...}",
  },
  {
    ID: "e326eb13-4853-4943-a76d-1d539dad67ac",
    model_name: "First Model",
    MSRP: 23,
    dealer_price: 23,
    demention_height: 34,
    demention_width: 34,
    demention_depth: 34,
    demention_rack_spaces: 34,
    images: '["c78be109fb5191bce16f3770f61947938.jpg",null,null]',
    applicable_option_ids:
      '["432bd2ca-a189-41a3-851b-9425f5d9d283","2c3977da-573e-4d3b-abec-c03fdc348fdf","b09b3e55-5135-46dc-a871-1ef8b25e2e71"]',
    description: "xxxxxxxx",
  },
];

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: theme.spacing(2), // Adjust this value to control the spacing
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1), // Ensure content shifts right to accommodate icon
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type Props = {
  editQuestion?: any;
  deleteQuestion?: any;
  items: FAQDataType[];
};
export const CollapsibleList: React.FC<Props> = ({
  editQuestion,
  deleteQuestion,
  items,
}): any => {
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  if (!items.length) return;

  return (
    <div>
      {items.map((item, index) => (
        <StyledAccordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <StyledAccordionSummary
            expandIcon={
              <IconButton>
                {expanded === item.id ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
              </IconButton>
            }
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography variant="h6">{item.question}</Typography>
            {editQuestion && (
              <div>
                <Tooltip title="Edit">
                  <IconButton onClick={(e) => editQuestion(e, item)}>
                    <EditCalendarIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={(e) => deleteQuestion(e, item)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </StyledAccordionSummary>
          <AccordionDetails>
            <List dense>
              <ListItem>
                <ListItemText primary="" secondary={item.answer} />
              </ListItem>
            </List>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};
