import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import { Carousel } from "../../../components/Carousel";
import { CustomizeOptions } from "../../../components/CustomizeOptions";
import {
  Option,
  filteredChipOptions,
  getHoverImage,
  transformOptions,
  transformOriginalOptions,
} from "../../../utils/option";
import { useOptionData } from "../../../hook/useOptionData";
import { getModelByID } from "../../../apis/model";
import { Model, generateRandomId } from "../../../utils/model";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { RootState } from "../../../store/store";
import { Loader } from "../../../components/Loader";
import { setQuoteAdminModels } from "../../../store/actions/adminAuthActions";

const useStyles = makeStyles(() => ({
  firstContent: {
    textAlign: "right",
  },
  CardContent: {
    width: "30%",
  },
  root: {
    display: "flex",
    gap: "10px",
    position: "relative",
  },
  addIconBtn: {
    position: "absolute",
    right: 0,
    top: "50% !important",
    transform: "translateY(-50%) !important",
    backgroundColor: "rgba(255, 255, 255, 0.5) !important",
    color: "black !important",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7) !important",
    },
  },
  hoveredImageWrapper: {
    position: "absolute",
    zIndex: 10000,
  },
  chipWrapper: {
    marginRight: "8px",
    cursor: "pointer",
  },
  hoveredImage: {
    width: "200px" /* Adjust the size as needed */,
    height: "100px",
    transition: "opacity 0.3s ease-in-out",
    opacity: 1,
    objectFit: "cover",
  },
  hidden: {
    opacity: 0,
  },
}));

export const QuoteCustomization: React.FC = () => {
  const navigate = useNavigate();
  const { ID } = useParams();
  const { options } = useOptionData();
  const { showSnackBar } = useGlobalSnackBar();

  const dispatch = useDispatch();

  const quoteAdminModels = useSelector(
    (state: RootState) => state.adminAuth.quoteAdminModels
  );

  const classes = useStyles();

  const [orderedOptions, setOrderedOptions] = useState<Option[]>([]);
  const [categories, setCategories] = useState<Option[]>([]);
  const [model, setModel] = useState<Model>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hoveredOption, setHoveredOption] = useState<Option | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (
    option: Option,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setMousePosition({ x: event.pageX, y: event.pageY });
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setMousePosition({ x: event.pageX, y: event.pageY });
  };

  const IDs = useMemo(() => {
    return ID?.split("*");
  }, [ID]);

  useEffect(() => {
    const fetchModelData = async (ID: string) => {
      try {
        const modelData = await getModelByID({ ID });
        setModel({ ...modelData });
        setLoading(false);
      } catch (error) {
        showSnackBar("Failed to fetch model", "error");
      }
    };

    if (IDs) {
      fetchModelData(IDs[0]);
    } else {
      setLoading(false);
    }
  }, [IDs]);

  const makeOrderedOptionData = useCallback(() => {
    const orderedOptionData = transformOriginalOptions(
      transformOptions(options, true),
      true
    );
    const _orderedOptionData = orderedOptionData?.filter(
      (option: Option) => !option.is_category
    );
    const _orderedCategoryData = orderedOptionData?.filter(
      (option: Option) => option.is_category
    );
    setOrderedOptions(_orderedOptionData);
    setCategories(_orderedCategoryData);
  }, [options]);

  useEffect(() => {
    makeOrderedOptionData();
  }, [options]);

  const handleFinish = useCallback(() => {
    if (!orderedOptions.length) return;
    const choseOptions = orderedOptions.filter((option: any) => option.isAdded);

    const _quoteModel = {
      ...model,
      chose_options: choseOptions.map((item: any) => ({
        ID: item.ID,
        MSRP: item.MSRP,
        dealer_price: item.dealer_price,
      })),
    };

    let newQuoteModels = quoteAdminModels;

    if (
      IDs?.[1] &&
      newQuoteModels.filter((res: Model) => res._id === IDs?.[1])?.length
    ) {
      // edit section
      newQuoteModels = newQuoteModels.map((res: Model) => {
        if (res._id === IDs[1]) {
          return { ...res, ..._quoteModel };
        }
        return res;
      });
    } else {
      // add section
      newQuoteModels.push({ ..._quoteModel, _id: generateRandomId() });
    }

    dispatch(setQuoteAdminModels(newQuoteModels));
    navigate(`/quote_builder`);
  }, [model, orderedOptions]);

  const getAvailableOtpions = useMemo(() => {
    return (
      model?.applicable_option_ids &&
      filteredChipOptions(
        options,
        JSON.parse(model.applicable_option_ids)
      )?.map((res) => (
        <Chip
          key={res?.ID}
          label={res?.option_name}
          variant="outlined"
          onMouseEnter={(e) => handleMouseEnter(res, e)}
          onMouseLeave={handleMouseLeave}
          className={classes.chipWrapper}
          onMouseMove={handleMouseMove}
        />
      ))
    );
  }, [model, options]);

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <div className="customize_box">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container sx={{ pr: { xl: "15%" }, pl: { xl: "15%" } }}>
          <Grid item xs={12} sm={12} md={12} sx={{ mb: 3 }}>
            <Card className={classes.root}>
              <CardContent
                className={`${classes.firstContent} ${classes.CardContent}`}
              >
                <Typography gutterBottom variant="h5" component="div">
                  {model?.model_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Dimensions
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Height: {model?.demention_height}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Width: {model?.demention_width}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Depth: {model?.demention_depth}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Rack Spaces: {model?.demention_rack_spaces}
                </Typography>
              </CardContent>
              <Carousel
                images={
                  model?.images
                    ? JSON.parse(model.images)?.filter(
                        (item: any) => item !== null
                      )
                    : []
                }
              />
              <CardContent className={classes.CardContent}>
                <Typography variant="body2">
                  Part # {model?.part_number}
                </Typography>
                <Typography variant="body2">MSRP: {model?.MSRP}</Typography>
                <Typography variant="body2">
                  Dealer Price: {model?.dealer_price}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Typography variant="body2" color="text.secondary" sx={{ mt: -2 }}>
            Applicable Options:{" "}
            {getAvailableOtpions?.length ? getAvailableOtpions : <i>Empty</i>}
          </Typography>

          {hoveredOption && (
            <Box
              sx={{
                top: mousePosition.y + 10, // Offset by 10 pixels to avoid cursor overlap
                left: mousePosition.x + 10,
                pointerEvents: "none", // Ensures the image does not capture mouse events
              }}
              className={classes.hoveredImageWrapper}
            >
              {JSON.parse(hoveredOption.images)?.[0] ? (
                <img
                  src={getHoverImage(hoveredOption.images) || ""}
                  className={`${classes.hoveredImage} ${
                    hoveredOption ? "" : classes.hidden
                  }`}
                />
              ) : (
                <i>No images</i>
              )}
            </Box>
          )}
        </Grid>

        <Grid
          container
          sx={{ pr: { xl: "15%" }, pl: { xl: "15%" }, display: "block" }}
        >
          {orderedOptions && (
            <CustomizeOptions
              options={orderedOptions}
              categories={categories}
              setOrderedOptions={setOrderedOptions}
              makeOrderedOptionData={makeOrderedOptionData}
              handleFinish={handleFinish}
              activeQuoteModel={model}
            />
          )}
        </Grid>
      </Box>
    </div>
  );
};
