import { get, post, patch } from "../helpers/admin_api_helper";
import { post as userPost, get as userGet } from "../helpers/user_api_helper";

export const getQuote = () => get(`/quote`);
export const getQuoteByID = (data: { ID?: string }) => get(`/quote/${data.ID}`);
export const createQuote = (data: {}) => post(`/quote`, data);
export const updateQuote = (data: { ID?: string, status: string }) => patch(`quote/${data.ID}`, data)
export const getByDealerID = (data: {}) => post(`/quote/get`, data);
export const getQuoteSearchAll = (data: {}) => post(`/quote/search`, data);
export const getQuoteAll = (data: {}) => post(`/quote/searchAll`, data);

export const createUserQuote = (data: {}) => userPost(`/quote`, data);
export const getQuoteByDealerID = (data: {}) => userPost(`/quote/get`, data);
export const getUserQuoteByID = (data: { ID?: string }) => userGet(`/quote/${data.ID}`);
