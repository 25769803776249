import React, { useState } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { UserAuthService } from "../../../services/UserAuthService";
import { RootState } from "../../../store/store";
import { Input } from "../../../components/Input";
import { Link } from "react-router-dom";

export const UserLoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const error = useSelector((state: RootState) => state.userAuth.error);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const res = await UserAuthService.login(email, password, dispatch);
      if (res) {
        window.location.href = "/";
      }
    } catch (error: any) {
      console.error(error);
    }
  };
  return (
    <Grid container>
      <Grid
        item
        xs={9}
        sx={{
          background: "url(../user_login_bg.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
      <Grid
        item
        xs={3}
        sx={{
          background: "#212121",
          height: "100vh",
          p: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <Typography
              variant="h4"
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Box component="span" sx={{ color: "#0057b7", mr: 1 }}>
                <img src="../../company_logo.png" width={100} />
              </Box> */}
              <Box component="span" sx={{ color: "#ffd700" }}>
                SIGN IN
              </Box>
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {error && (
                <Typography variant="body2" color="error" sx={{ mb: 1.5 }}>
                  {error}
                </Typography>
              )}
              <Input
                id="email"
                label="Email Address"
                name="email"
                size="medium"
                required
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  style: { color: "#fff" },
                }}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required
                size="medium"
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  style: { color: "#fff" },
                }}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sx={{ mt: 2, color: "#FFFFFF" }}>
              <Link
                to="/user/forgot-password"
                className="custom-link"
                style={{ color: "#FFFFFF" }}
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};
