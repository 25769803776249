import React from "react";
import { Drawer, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { DRAWER_WIDTH } from "../../constants/Constant";

interface Props {
  window?: () => Window;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  drawer: JSX.Element;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    "&>div": {
      background: "var(--kendo-color-secondary-on-subtle)",
    },
  },
  mobileWrapper: {
    "&>div:nth-child(3)": {
      background: "var(--kendo-color-secondary-on-subtle)",
    },
  }
}));

export const Sidebar: React.FC<Props> = ({
  window,
  handleDrawerToggle,
  mobileOpen,
  drawer,
}) => {
  const classes = useStyles();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
        className={classes.mobileWrapper}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
        open
        className={classes.wrapper}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
