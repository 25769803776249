import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, FormHelperText, Typography } from "@mui/material";

import { Input } from "../../../components/Input";
import { SelectComponent } from "../../../components/Select";
import { phoneRegex, usStates, zipRegex } from "../../../constants/Constant";
import { getUserDealerByID, updateUserDealer } from "../../../apis/dealer";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { RootState } from "../../../store/store";
import { Loader } from "../../../components/Loader";

export const UserSettings: React.FC = () => {
  const [dealer, setDealer] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [phone, setPhone] = useState<string>();
  const [zip, setZip] = useState<string>();
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
  const [isZipValid, setIsZipValid] = useState<boolean>(true);

  const { showSnackBar } = useGlobalSnackBar();

  const currentUser = useSelector((state: RootState) => state.userAuth.user);

  useEffect(() => {
    const fetchDealer = async () => {
      try {
        const dealerData = await getUserDealerByID({ ID: currentUser.id });
        setDealer({ ...dealerData });
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch dealer", error);
        showSnackBar("Server error", "error");
      }
    };

    if (currentUser?.id) {
      fetchDealer();
    } else {
      setLoading(false);
    }
  }, [currentUser, showSnackBar]);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setPhone(newValue);
    setIsPhoneValid(phoneRegex.test(newValue));
  };

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setZip(newValue);
    setIsZipValid(zipRegex.test(newValue));
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formValues: any = Object.fromEntries(formData.entries());
    formValues["ID"] = currentUser.id;

    if (!isPhoneValid || !isZipValid) {
      showSnackBar("Form is invalid! Please check the fields...", "error");
      return;
    }

    for (const key in formValues) {
      if (formValues[key] === "") {
        delete formValues[key];
      }
    }
    try {
      await updateUserDealer(formValues);
      showSnackBar("Updated this dealer!");
    } catch (err) {}
  };

  if (isLoading || !dealer) return <Loader isLoading={isLoading} />;

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h5" align="center" mt={2.5} mb={1.5}>
        Account # 2563-01
      </Typography>
      <Typography
        variant="h6"
        align="center"
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2.5,
          mb: 1.5,
          gap: 1,
          justifyContent: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            width: { md: "40%" },
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2.5,
              mb: 1.5,
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Typography sx={{ width: { md: "40%" } }} align="right">
              Company Name:
            </Typography>
            <Input defaultValue={dealer.dealer_name} name="dealer_name" />
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2.5,
              mb: 1.5,
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Typography sx={{ width: { md: "40%" } }} align="right">
              Contact:
            </Typography>
            <Input defaultValue={dealer.contact_name} name="contact_name" />
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2.5,
              mb: 1.5,
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Typography sx={{ width: { md: "40%" } }} align="right">
              E-mail Address:
            </Typography>
            <Input defaultValue={dealer.email} name="email" />
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2.5,
              mb: 1.5,
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Typography sx={{ width: { md: "40%" } }} align="right">
              Phone:
            </Typography>
            <Input
              defaultValue={dealer.phone}
              name="phone"
              value={phone}
              onChange={handlePhoneChange}
              error={!isPhoneValid}
            />
          </Typography>
          {!isPhoneValid && (
            <FormHelperText error sx={{ textAlign: "center" }}>
              Please enter a valid phone number in the format +1 (707) 123-4567
            </FormHelperText>
          )}
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2.5,
              mb: 1.5,
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Typography sx={{ width: { md: "40%" } }} align="right">
              Address:
            </Typography>
            <Input defaultValue={dealer.address} name="address" />
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2.5,
              mb: 1.5,
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Typography sx={{ width: { md: "40%" } }} align="right">
              City:
            </Typography>
            <Input defaultValue={dealer.city} name="city" />
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2.5,
              mb: 1.5,
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Typography sx={{ width: { md: "40%" } }} align="right">
              State:
            </Typography>
            <SelectComponent
              defaultValue={dealer.state}
              name="state"
              menuItems={usStates}
            />
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2.5,
              mb: 1.5,
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Typography sx={{ width: { md: "40%" } }} align="right">
              Zip:
            </Typography>
            <Input
              defaultValue={dealer.zip}
              name="zip"
              value={zip}
              onChange={handleZipChange}
              error={!isZipValid}
            />
          </Typography>
          {!isZipValid && (
            <FormHelperText error sx={{ textAlign: "center" }}>
              Please enter a valid zip in the format xxxxx-xxxx
            </FormHelperText>
          )}
        </Typography>
      </Typography>
      <Typography align="center" mt={5.5} mb={1.5}>
        <Button sx={{ width: "150px" }} variant="contained" type="submit">
          Save
        </Button>
      </Typography>
    </Box>
  );
};
