import React, { useState } from "react";
import {
  Stack,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { SelectComponent } from "../Select";

interface PaginationProps {
  pageCount: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number;
}

interface MenuItemData {
  value: string;
  label: string;
}

const BasicPagination: React.FC<PaginationProps> = ({
  pageCount,
  page,
  setPage,
  setPageNumber,
  pageNumber,
}) => {
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const menuItems: MenuItemData[] = [
    { value: "4", label: "4" },
    { value: "8", label: "8" },
  ];

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <SelectComponent
        menuItems={menuItems}
        width="50px"
        value={String(pageNumber)}
        onChange={(e) => setPageNumber(Number(e.target.value))}
      />
      <Pagination
        count={pageCount}
        page={page}
        onChange={handlePageChange}
        siblingCount={1}
        boundaryCount={1}
      />
    </Stack>
  );
};

export default BasicPagination;
