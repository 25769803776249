import { Action } from "redux";

// Action types
export enum AuthActionType {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGOUT = "LOGOUT",
  SET_QUOTE_ADMIN_MODELS = "SET_QUOTE_ADMIN_MODELS",
}

// Action creators
export interface LoginSuccessAction
  extends Action<typeof AuthActionType.LOGIN_SUCCESS> {
  payload: { token: string };
}

export interface LoginFailureAction
  extends Action<typeof AuthActionType.LOGIN_FAILURE> {
  payload: { error: string };
}

export interface LogoutAction extends Action<typeof AuthActionType.LOGOUT> {}

export interface SetQuoteAdminModelsAction
  extends Action<typeof AuthActionType.SET_QUOTE_ADMIN_MODELS> {
  payload: { quoteModels: any };
}

// Union type for all auth actions
export type AuthActionTypes =
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutAction
  | SetQuoteAdminModelsAction;

// Action creators
export const loginSuccess = (token: string): LoginSuccessAction => ({
  type: AuthActionType.LOGIN_SUCCESS,
  payload: { token },
});

export const loginFailure = (error: string): LoginFailureAction => ({
  type: AuthActionType.LOGIN_FAILURE,
  payload: { error },
});

export const logout = (): LogoutAction => ({
  type: AuthActionType.LOGOUT,
});

export const setQuoteAdminModels = (quoteModels: any): any => ({
  type: AuthActionType.SET_QUOTE_ADMIN_MODELS,
  payload: { quoteModels },
});
