import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  ListItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";

import DefaultTable from "../../../components/Table/DefaultTable";
import { SelectComponent } from "../../../components/Select";
import { MenuItemData } from "../../../types/global.types";
import { useCompanyData } from "../../../hook/useCompanyData";
import { getQuoteSearchAll } from "../../../apis/quote";
import { Loader } from "../../../components/Loader";
import { getQuoteNumber } from "../../../utils/quote";

interface StatCardProps {
  value: number | string;
  description: string;
}

const StatCard: React.FC<StatCardProps> = ({ value, description }) => (
  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
    <Card sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}>
      <CardContent sx={{ pb: "10px !important" }}>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

const headerData: string[] = ["Dealer", "Contact", "Quote #", "Date", "Status"];

const menuItems: MenuItemData[] = [
  { value: "30days", label: "30-Day" },
  { value: "lastMonth", label: "Last Month" },
  { value: "365days", label: "365 Day" },
  { value: "yearToDate", label: "Year to Date" },
  { value: "allTime", label: "All Time" },
];

export const Dashboard: React.FC = () => {
  const [snapshotTerm, setSnapshotTerm] = useState<string>("30days");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [customerNumber, setCustomerNumber] = useState<number>(0);
  const [salesNumber, setSalesNumber] = useState<number>(0);
  const [closePercentage, setClosePercentage] = useState<number>(0);

  const { company } = useCompanyData();

  const [isLoading, setIsLoading] = useState<boolean | false>(true);
  const [quotes, setQuotes] = useState([]);

  const fetchQuotes = async () => {
    try {
      const {
        data: quoteData,
        counts,
        customerNumber,
        salesNumber,
        closePercentage,
      } = await getQuoteSearchAll({
        page: page + 1,
        pageSize,
        dateRange: snapshotTerm || "30days",
      });

      const transformedData = quoteData?.map((quote: any) => ({
        // activity: quote.activity,
        dealer:
          quote.dealer_id === "ADMIN" ? (
            company?.company_name
          ) : (
            <ListItem
              disablePadding
              component={Link}
              to={`/dealers/${quote.dealerId}`}
            >
              {quote.dealer_name}
            </ListItem>
          ),
        contact:
          quote.dealer_id === "ADMIN"
            ? company?.contact_name
            : quote?.contact_name,
        quotes: (
          <ListItem
            disablePadding
            component={Link}
            to={`/quote/${quote.quoteId}`}
          >
            {quote.no}
          </ListItem>
        ),
        last_activity: moment(quote.createdAt).format("MM/DD/YYYY"),
        status: quote?.quote_status,
      }));

      setQuotes(transformedData);

      setCount(counts);
      setCustomerNumber(customerNumber);
      setSalesNumber(salesNumber);
      setClosePercentage(closePercentage || 0);

      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchQuotes();
  }, [company, page, pageSize, snapshotTerm]);

  const handleChange = (event: SelectChangeEvent) => {
    setPage(0);
    setSnapshotTerm(event.target.value);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPageSize(Number(e.target.value));
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mb={3}
        >
          <Grid
            item
            xs={4}
            sx={{ display: "flex", justifyContent: "center", m: 1 }}
          >
            <SelectComponent
              menuItems={menuItems}
              width="180px"
              label="Snapshot Term"
              value={snapshotTerm}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <StatCard value={customerNumber} description="Unique Customers" />
          <StatCard value={count} description="Quotes" />
          <StatCard value={salesNumber} description="Sales" />
          <StatCard value={`${closePercentage?.toFixed(2)}%`} description="Close" />
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" align="center" mt={2.5} mb={1.5}>
          Recent Activity
        </Typography>
        <DefaultTable
          headerData={headerData}
          rowsData={quotes}
          rowsPerPage={pageSize}
          page={page}
          count={count}
          handleChangePage={(
            e: React.ChangeEvent<HTMLInputElement>,
            page: number
          ) => handleChangePage(page)}
          handleChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(e)
          }
        />
      </Box>
    </div>
  );
};
