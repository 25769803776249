import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";

import DefaultTable from "../../../components/Table/DefaultTable";
import { Input } from "../../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getQuoteByDealerID } from "../../../apis/quote";
import moment from "moment";
import { Loader } from "../../../components/Loader";
import { ModelOptionDataType } from "../../../types/global.types";
import { Model, generateRandomId } from "../../../utils/model";
import { setQuoteModels } from "../../../store/actions/userAuthActions";
import { useModelData } from "../../../hook/useModelData";

interface StatCardProps {
  value: number | string;
  description: string;
}

const StatCard: React.FC<StatCardProps> = ({ value, description }) => (
  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
    <Card sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}>
      <CardContent sx={{ pb: "10px !important" }}>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

const headerData: string[] = [
  "Dealer",
  "Contact",
  "Quote #",
  "Date",
  "Status",
  "",
];

export const UserQuotes: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { models } = useModelData("dealer");

  const [isLoading, setIsLoading] = useState<boolean | false>(true);
  const [quotes, setQuotes] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [lastMonthQuotesNumber, setLastMonthQuotesNumber] = useState<number>(0);
  const [yearQuotesNumber, setYearQuotesNumber] = useState<number>(0);

  const currentUser = useSelector((state: RootState) => state.userAuth.user);

  const reQuoteHandle = (quote: any) => {
    const modelOptionData = quote?.model_option_ids
      ? JSON.parse(quote?.model_option_ids)
      : [];

    const _modelOptionData = modelOptionData?.map(
      (res: ModelOptionDataType) => {
        let _model = models.find(
          (model: Model) => model.ID === res.model_id.ID
        );

        return {
          ..._model,
          chose_options: res.option_ids,
          qty: res.qty,
          _id: generateRandomId(),
        };
      }
    );

    dispatch(setQuoteModels(_modelOptionData));

    history("/_quote_builder");
  };

  const fetchQuotes = async () => {
    try {
      const {
        data: quoteData,
        counts,
        lastMonthQuotesNumber,
        yearQuotesNumber,
      } = await getQuoteByDealerID({
        dealer_id: currentUser?.id,
        page: page + 1,
        pageSize,
      });

      const transformedData = quoteData?.map((quote: any) => ({
        dealer: quote?.dealer_name,
        contact: quote?.contact_name,
        quotes: (
          <ListItem
            disablePadding
            component={Link}
            to={`/_quote/${quote.quoteId}`}
          >
            {quote.no}
          </ListItem>
        ),
        last_activity: moment(quote.createdAt).format("MM/DD/YYYY"),
        status: quote.quote_status,
        edit: (
          <Tooltip title="Re-quote">
            <IconButton onClick={() => reQuoteHandle(quote)}>
              <ReplyIcon />
            </IconButton>
          </Tooltip>
        ),
      }));

      setQuotes(transformedData);
      setCount(counts);
      setLastMonthQuotesNumber(lastMonthQuotesNumber);
      setYearQuotesNumber(yearQuotesNumber);

      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!currentUser || !models) return;

    fetchQuotes();
  }, [currentUser, models, page, pageSize]);

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPageSize(Number(e.target.value));
  };

  const handleQuoteBuild = () => {
    dispatch(setQuoteModels([]));
    history("/_models");
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <StatCard
            value={lastMonthQuotesNumber}
            description="Quotes (Last 30 Days)"
          />
          <StatCard value={yearQuotesNumber} description="Quotes (This Year)" />
          <StatCard value={count} description="Quotes (All time)" />
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => handleQuoteBuild()}
              sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}
            >
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  +
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Build Quote
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input label="Search:" />
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={2} direction="row">
              <Button variant="contained">Apply</Button>
              <Button variant="outlined">Reset</Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <h3 style={{ textAlign: "center" }}>Recent Activity</h3>
        <DefaultTable
          headerData={headerData}
          rowsData={quotes}
          rowsPerPage={pageSize}
          page={page}
          count={count}
          handleChangePage={(
            e: React.ChangeEvent<HTMLInputElement>,
            page: number
          ) => handleChangePage(page)}
          handleChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(e)
          }
        />
      </Box>
    </>
  );
};
