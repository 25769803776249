import React, { ReactNode, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";

import DefaultTable from "../../../components/Table/DefaultTable";
import { Input } from "../../../components/Input";
import { CustomModal } from "../../../components/modal/CustomModal";
import { usStates } from "../../../constants/Constant";
import { SelectComponent } from "../../../components/Select";
import { findDealersAndQuotesAll, inviteDealer } from "../../../apis/dealer";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { useCompanyData } from "../../../hook/useCompanyData";
import { Loader } from "../../../components/Loader";
import { checkActiveStatus } from "../../../utils/dealer";
import { usePermissionHook } from "../../../hook/usePermissionHook";

interface StatCardProps {
  value: number | string;
  description: string;
}

const StatCard: React.FC<StatCardProps> = ({ value, description }) => (
  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
    <Card sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}>
      <CardContent sx={{ pb: "10px !important" }}>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

interface TRowData<T> {
  [key: string]: T;
}

const headerData: string[] = [
  "Dealer",
  "Contact",
  "Quotes",
  "Last Activity",
  "Status",
];

const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

const getThirtyDaysAgoNumber = (data: any) => {
  const all = data.filter(
    (item: { createdAt: string }) => new Date(item.createdAt) > thirtyDaysAgo
  )?.length;
  const activities = data.filter(
    (item: { createdAt: string; status: string }) =>
      new Date(item.createdAt) > thirtyDaysAgo && item.status === "Activity"
  )?.length;
  return [all, activities];
};

export const Dealers: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [companyError, setCompanyError] = useState(true);
  const [contactError, setContactError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [dealers, setDealers] = useState<TRowData<ReactNode>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean | false>(true);
  const [thirtyDaysAgoNumbers, setThirtyDaysAgoNumbers] = useState<number[]>([
    0, 0,
  ]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);

  const { company } = useCompanyData();

  const { showSnackBar } = useGlobalSnackBar();
  const { permissionReadOnly } = usePermissionHook();

  const fetchDealers = async () => {
    try {
      const { data: dealerData, counts } = await findDealersAndQuotesAll({
        page: page + 1,
        pageSize,
      });

      const transformedData = dealerData.map((dealer: any) => ({
        dealer: (
          <ListItem
            disablePadding
            component={Link}
            to={`/dealers/${dealer.ID}`}
          >
            {dealer.dealer_name || "Undefined"}
          </ListItem>
        ),
        contact: dealer.contact_name,
        quotes: dealer.quote_number,
        last_activity:
          dealer.last_activity_date &&
          moment(dealer.last_activity_date).format("MM/DD/YYYY"),
        status: checkActiveStatus(
          dealer.created_at,
          company?.active_dealer_setting_number +
            company?.active_dealer_setting_date,
          dealer.quote_number
        ),
      }));

      setDealers(transformedData);
      setCount(counts);

      setThirtyDaysAgoNumbers(getThirtyDaysAgoNumber(dealerData));
      setIsLoading(false);
    } catch (error) {
      showSnackBar("Failed to fetch admins", "error");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (company) {
      fetchDealers();
    }
  }, [page, pageSize, company]);

  const permissionErrorMessage = () => {
    showSnackBar("Not allowed permission!", "error");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    if (companyError || contactError || emailError) {
      showSnackBar("Form is invalid! Please check the fields...", "error");
    } else {
      const formData = new FormData(e.target);
      const formValues: any = Object.fromEntries(formData.entries());

      for (const key in formValues) {
        if (formValues[key] === "") {
          delete formValues[key];
        }
      }

      try {
        await inviteDealer(formValues);
        showSnackBar("Sent invitation!");
        setOpenModal(false);
      } catch (err) {
        showSnackBar("Sever error.", "error");
      }
    }
  };

  const handleCompanyChange = (e: any) => {
    if (e.target.value.length < 1) {
      setCompanyError(true);
    } else {
      setCompanyError(false);
    }
  };

  const handleContactChange = (e: any) => {
    if (e.target.value.length < 1) {
      setContactError(true);
    } else {
      setContactError(false);
    }
  };

  const handleEmailChange = (e: any) => {
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPageSize(Number(e.target.value));
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <StatCard
            value={thirtyDaysAgoNumbers[0]}
            description="New dealers (last 30 days)"
          />
          <StatCard
            value={thirtyDaysAgoNumbers[1]}
            description="Active Dealers (activity in the last 30 days)"
          />
          <StatCard value={count} description="Total dealers" />
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => setOpenModal(true)}
              sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}
            >
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  +
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Invite Dealer
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input label="Search:" />
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={2} direction="row">
              <Button variant="contained">Apply</Button>
              <Button variant="outlined">Reset</Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h5" noWrap align="center" sx={{ mb: 2, mt: 3 }}>
          Recent Activity
        </Typography>
        <DefaultTable
          headerData={headerData}
          rowsData={dealers}
          rowsPerPage={pageSize}
          page={page}
          count={count}
          handleChangePage={(
            e: React.ChangeEvent<HTMLInputElement>,
            page: number
          ) => handleChangePage(page)}
          handleChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(e)
          }
        />
      </Box>
      <CustomModal
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
        width={500}
        handleSubmit={handleSubmit}
      >
        <Typography variant="h5" noWrap align="center" sx={{ mb: 2 }}>
          Invite a Dealer
        </Typography>
        <Input
          label="Company Name"
          name="dealer_name"
          required
          error={companyError}
          onChange={handleCompanyChange}
          sx={{ mb: 2 }}
        />
        <Input
          label="Contact"
          name="contact_name"
          required
          error={contactError}
          onChange={handleContactChange}
          sx={{ mb: 2 }}
        />
        <Input
          label="E-mail Address"
          name="email"
          required
          error={emailError}
          onChange={handleEmailChange}
          sx={{ mb: 2 }}
        />
        <Input label="Phone" name="phone" sx={{ mb: 2 }} />
        <Input label="Address" name="address" sx={{ mb: 2 }} />
        <Input label="City" name="city" sx={{ mb: 2 }} />
        <SelectComponent label="State" name="state" menuItems={usStates} />
        <Input label="Zip" name="zip" sx={{ mb: 2, mt: 2 }} />
        <Input
          label="Message"
          name="message"
          sx={{ mb: 2, mt: 1 }}
          rows={5}
          defaultValue={company?.invite_message_description}
        />
        <Typography align="center">
          <Button variant="contained" color="primary" type="submit">
            Send Invitation
          </Button>
        </Typography>
      </CustomModal>
    </>
  );
};
