import * as React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface MenuItemData {
  value: string;
  label: string;
  disabled?: boolean;
}

interface SelectProps {
  label?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  width?: string;
  height?: number;
  onChange?: (event: SelectChangeEvent) => void;
  menuItems: MenuItemData[];
}

export const SelectComponent: React.FC<SelectProps> = ({
  label,
  name,
  value,
  defaultValue,
  width,
  height,
  onChange,
  menuItems,
}) => {
  return (
    <FormControl fullWidth={width ? false : true}>
      <InputLabel id="demo-simple-select-label" size="small">
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        label={label}
        value={value}
        name={name}
        onChange={onChange}
        defaultValue={defaultValue}
        sx={{
          width: width,
          minWidth: 120,
          height: height || 40,
          textAlign: "left",
        }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.value}
            value={menuItem.value}
            disabled={menuItem?.disabled}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
