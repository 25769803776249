import { Action } from "redux";

// Action types
export enum UserAuthActionType {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGOUT = "LOGOUT",

  // regarding quote store
  GET_QUOTE_MODELS = "GET_QUOTE_MODELS",
  SET_QUOTE_MODELS = "SET_QUOTE_MODELS",
}

// Action creators
export interface UserLoginSuccessAction
  extends Action<typeof UserAuthActionType.LOGIN_SUCCESS> {
  payload: { token: string };
}

export interface UserLoginFailureAction
  extends Action<typeof UserAuthActionType.LOGIN_FAILURE> {
  payload: { error: string };
}

export interface UserLogoutAction
  extends Action<typeof UserAuthActionType.LOGOUT> {}

export interface GetQuoteModelsAction
  extends Action<typeof UserAuthActionType.GET_QUOTE_MODELS> {}

export interface SetQuoteModelsAction
  extends Action<typeof UserAuthActionType.SET_QUOTE_MODELS> {
  payload: { quoteModels: any };
}

// Union type for all auth actions
export type UserAuthActionTypes =
  | UserLoginSuccessAction
  | UserLoginFailureAction
  | UserLogoutAction
  | GetQuoteModelsAction
  | SetQuoteModelsAction;

// Action creators
export const userLoginSuccess = (token: string): UserLoginSuccessAction => ({
  type: UserAuthActionType.LOGIN_SUCCESS,
  payload: { token },
});

export const userLoginFailure = (error: string): UserLoginFailureAction => ({
  type: UserAuthActionType.LOGIN_FAILURE,
  payload: { error },
});

export const userLogout = (): UserLogoutAction => ({
  type: UserAuthActionType.LOGOUT,
});

// export const getQuoteModels = (): GetQuoteModelsAction => ({
//   type: UserAuthActionType.GET_QUOTE_MODELS,
// });

export const setQuoteModels = (quoteModels: any): any => ({
  type: UserAuthActionType.SET_QUOTE_MODELS,
  payload: { quoteModels },
});
