import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Card,
  CardContent,
  Grid,
  ListItem,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import DefaultTable from "../../../components/Table/DefaultTable";
import { RootState } from "../../../store/store";
import { getUserDealerByID } from "../../../apis/dealer";
import { Loader } from "../../../components/Loader";
import { getQuoteByDealerID } from "../../../apis/quote";
import { useModelData } from "../../../hook/useModelData";

interface StatCardProps {
  value: number | string;
  description: string;
}

const StatCard: React.FC<StatCardProps> = ({ value, description }) => (
  <Grid
    item
    xs={3}
    sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
  >
    <Card sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}>
      <CardContent sx={{ pb: "10px !important" }}>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

const headerData: string[] = ["Dealer", "Contact", "Quote #", "Date", "Status"];

export const UserDashboard: React.FC = () => {
  const history = useNavigate();
  const currentUser = useSelector((state: RootState) => state.userAuth.user);

  const { models } = useModelData("dealer");

  const [dealer, setDealer] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    const fetchDealer = async () => {
      try {
        const dealerData = await getUserDealerByID({ ID: currentUser.id });
        setDealer({ ...dealerData });
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch dealer", error);
      }
    };

    if (currentUser?.id) {
      fetchDealer();
    } else {
      setIsLoading(false);
    }
  }, [currentUser]);

  const fetchQuotes = async () => {
    try {
      const { data: quoteData, counts } = await getQuoteByDealerID({
        dealer_id: currentUser?.id,
        page: page + 1,
        pageSize,
      });

      const transformedData = quoteData?.map((quote: any) => ({
        dealer: quote?.dealer_name,
        contact: quote?.contact_name,
        quotes: (
          <ListItem
            disablePadding
            component={Link}
            to={`/_quote/${quote.quoteId}`}
          >
            {quote.no}
          </ListItem>
        ),
        last_activity: moment(quote.createdAt).format("MM/DD/YYYY"),
        status: quote.quote_status,
      }));

      setQuotes(transformedData);
      setCount(counts);

      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!currentUser) return;

    fetchQuotes();
  }, [currentUser, page, pageSize]);

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPageSize(Number(e.target.value));
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h3" align="center" mt={2.5} mb={2.5}>
          Welcome, {dealer?.dealer_name}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "space-between" }}
          onClick={() => history("/_models")}
        >
          {/* <StatCard value={35} description="Previous Quotes" /> */}
          <StatCard value={models?.length} description="Browser By Model" />
          {/* <StatCard value={25} description="Browse By Option" /> */}
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => history("/_models")}
              sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}
            >
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  +
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  New Quote
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" align="center" mt={2.5} mb={1.5}>
          Activity
        </Typography>
        <DefaultTable
          headerData={headerData}
          rowsData={quotes}
          rowsPerPage={pageSize}
          page={page}
          count={count}
          handleChangePage={(
            e: React.ChangeEvent<HTMLInputElement>,
            page: number
          ) => handleChangePage(page)}
          handleChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(e)
          }
        />
      </Box>
    </>
  );
};
