import { useState, useEffect, useCallback } from "react";

import { getOption, getUserOption } from "../apis/option";

export const useOptionData = (apiType = "admin"): any => {
  const [options, setOptions] = useState<[]>([]);

  const fetchOptionData = useCallback(async () => {
    try {
      let optionData;
      if (apiType === "admin") {
        optionData = await getOption();
      } else {
        optionData = await getUserOption();
      }
      setOptions(optionData);
    } catch (error) {
      console.error("Failed to fetch options", error);
    }
  }, []);

  useEffect(() => {
    fetchOptionData();
  }, [fetchOptionData]);

  return { options };
};
