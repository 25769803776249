import { Reducer } from "redux";
import { AuthActionType, AuthActionTypes } from "../actions";
import { jwtDecode } from "jwt-decode";

export interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  error: string | null;
  admin: any;
  quoteAdminModels: any;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: localStorage.getItem("admin_access_token") || null,
  error: null,
  admin: localStorage.getItem("admin_access_token")
    ? jwtDecode(localStorage.getItem("admin_access_token") || "")
    : null,
    quoteAdminModels: [],
};

export const adminAuthReducer: Reducer<AuthState, AuthActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AuthActionType.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        error: null,
        admin: jwtDecode(action.payload.token),
      };
    case AuthActionType.LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        error: action.payload.error,
      };
    case AuthActionType.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        error: null,
        admin: null,
      };

    // regarding quote store
    case AuthActionType.SET_QUOTE_ADMIN_MODELS:
      return {
        ...state,
        quoteAdminModels: action.payload.quoteModels,
      };
    default:
      return state;
  }
};
