import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Avatar,
  Toolbar,
  Divider,
  ListItemButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import HomeIcon from "@mui/icons-material/Home";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import SettingsIcon from "@mui/icons-material/Settings";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import ShareIcon from "@mui/icons-material/Share";
import QuizIcon from "@mui/icons-material/Quiz";
import DescriptionIcon from '@mui/icons-material/Description';

import { SnackbarProvider } from "../SnackbarContext";
import { Header } from "../../components/layout/Header";
import { AdminAuthService } from "../../services/AdminAuthService";
import { useCompanyData } from "../../hook/useCompanyData";
import { Sidebar } from "../../components/layout/Sidebar";

interface AdminLayoutProps {
  children: ReactNode;
  title: string;
}

const useStyles = makeStyles(() => ({
  listItem: {
    "& svg": {
      color: "white",
    },
  },
  listColor: {
    color: "var(--kendo-color-app-surface)",
  },
  activeListItem: {
    background: "var(--kendo-color-base-subtle-hover)",
    "& svg": {
      color: "var(--kendo-color-base-on-surface)",
    },
    "&>div>div>span": {
      color: "var(--kendo-color-base-on-surface)",
    },
  },
}));

export const AdminLayout: React.FC<AdminLayoutProps> = ({
  children,
  title,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const { company } = useCompanyData();

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const logout = useCallback(() => {
    AdminAuthService.logout(dispatch);
    navigate("/admin/login");
  }, [dispatch, navigate]);

  const drawer = useMemo(() => {
    return (
      <div>
        <Toolbar />
        <Divider />
        <Avatar
          src={
            typeof company?.company_logo === "string"
              ? `${process.env.REACT_APP_COMPANY_LOGO_URL}/${company?.company_logo}`
              : undefined
          }
          sx={{
            display: "block",
            width: "100px",
            height: "100px",
            m: "0 auto",
            mt: 2,
            mb: 1,
          }}
        />
        <List>
          <ListItem
            disablePadding
            component={Link}
            to="/dashboard"
            className={
              location.pathname === "/dashboard" ? classes.activeListItem : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/models"
            className={
              location.pathname.includes("/models")
                ? classes.activeListItem
                : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <ModelTrainingIcon />
              </ListItemIcon>
              <ListItemText primary="Models" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/options"
            className={
              location.pathname.includes("/options")
                ? classes.activeListItem
                : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <DisplaySettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Options" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/dealers"
            className={
              location.pathname.includes("/dealers")
                ? classes.activeListItem
                : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <ShareIcon />
              </ListItemIcon>
              <ListItemText primary="Dealers" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/quotes"
            className={
              location.pathname.includes("/quote") ? classes.activeListItem : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Quotes" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/settings"
            className={
              location.pathname === "/settings" ? classes.activeListItem : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={Link}
            to="/faq"
            className={
              location.pathname === "/faq" ? classes.activeListItem : ""
            }
          >
            <ListItemButton className={classes.listItem}>
              <ListItemIcon>
                <QuizIcon />
              </ListItemIcon>
              <ListItemText primary="FAQ" className={classes.listColor} />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    );
  }, [company, classes, location]);

  return (
    <SnackbarProvider>
      <Header
        title={title}
        handleDrawerToggle={handleDrawerToggle}
        logout={logout}
      />
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawer={drawer}
      />
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          padding: "80px 10px 0",
          paddingLeft: { md: "250px" },
        }}
      >
        {children}
      </Box>
    </SnackbarProvider>
  );
};
