import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { UsersTab } from "./UsersTab";
import { SettingsTab } from "./SettingsTab";
import { InfoTab } from "./InfoTab";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Settings: React.FC = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Users" {...a11yProps(0)} />
            <Tab label="Settings" {...a11yProps(1)} />
            <Tab label="Info" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <UsersTab value={value} />
        <SettingsTab value={value} />
        <InfoTab value={value} />
      </Box>
    </>
  );
};
