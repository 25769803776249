import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

interface BasicModalProps {
  openModal: boolean;
  handleClose: () => void;
  children: any;
  width?: number;
  handleSubmit?: React.FormEventHandler<HTMLFormElement> | undefined;
}

export const CustomModal: React.FC<BasicModalProps> = ({
  openModal,
  handleClose,
  children,
  width,
  handleSubmit,
}) => {

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width || 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component='form' onSubmit={handleSubmit} noValidate>
          {children}
        </Box>
      </Modal>
    </div>
  );
}
