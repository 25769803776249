import { Dispatch } from "redux";
import {
  AuthActionTypes,
  loginFailure,
  loginSuccess,
} from "../store/actions/adminAuthActions";
import { signIn } from "../apis/admin";

interface DecodedToken {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  permission: string;
}

export const AdminAuthService = {
  login: async (
    email: string,
    password: string,
    dispatch: Dispatch<AuthActionTypes>
  ) => {
    try {
      const res = await signIn({ email: email, password: password })
      if (res?.error) {
        dispatch(loginFailure(res?.error));
      }
      if (res?.access_token) {
        localStorage.setItem("admin_access_token", res?.access_token);
        dispatch(loginSuccess(res?.access_token));
        return true;
      }
    } catch (error: any) {
      // Dispatch login failure action
      dispatch(loginFailure(error.message));
    }
  },

  logout: (dispatch: Dispatch<AuthActionTypes>) => {
    // Clear token from localStorage on logout
    localStorage.removeItem("admin_access_token");
  },

  getToken: () => {
    // Retrieve token from localStorage
    return localStorage.getItem("admin_access_token");
  },

  isAuthenticated: () => {
    // Check if token exists in localStorage
    return !!localStorage.getItem("admin_access_token");
  },
};
