import { Dispatch } from "redux";
import {
  UserAuthActionTypes,
  userLoginFailure,
  userLoginSuccess,
} from "../store/actions/userAuthActions";
import { signIn } from "../apis/dealer";

export const UserAuthService = {
  login: async (
    email: string,
    password: string,
    dispatch: Dispatch<UserAuthActionTypes>
  ) => {
    try {
      const res = await signIn({ email: email, password: password })
      if (res?.error) {
        dispatch(userLoginFailure(res?.error));
      }
      if (res?.access_token) {
        localStorage.setItem("user_access_token", res?.access_token);
        dispatch(userLoginSuccess(res?.access_token));
        return true;
      }
    } catch (error: any) {
      // Dispatch login failure action
      dispatch(userLoginFailure(error.message));
    }
  },

  logout: (dispatch: Dispatch<UserAuthActionTypes>) => {
    // Clear token from localStorage on logout
    localStorage.removeItem("user_access_token");
  },

  getToken: () => {
    // Retrieve token from localStorage
    return localStorage.getItem("user_access_token");
  },

  isAuthenticated: () => {
    // Check if token exists in localStorage
    return !!localStorage.getItem("user_access_token");
  },
};
