import React, { createContext, useContext, useState } from "react";
import { SnackBar } from "../components/SnackBar";


interface SnackbarContextType {
  showSnackBar: (message: string, messageType?: string) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const SnackbarProvider: React.FC<any> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<{ message: string; open: boolean; messageType?: string }>({
    message: "",
    open: false,
  });

  const showSnackBar = (message: string, messageType?: string ) => {
    setSnackbar({ message, open: true, messageType });
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackBar }}>
      {children}
      <SnackBar message={snackbar.message} open={snackbar.open} handleClose={handleClose} messageType={snackbar.messageType || undefined} />
    </SnackbarContext.Provider>
  );
};

export const useGlobalSnackBar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useGlobalSnackBar must be used within a SnackbarProvider");
  }
  return context;
};
