import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

import { Input } from "../../../components/Input";
import { deleteFile } from "../../../apis/fileUpload";
import {
  createOption,
  getOptionByID,
  updateOption,
} from "../../../apis/option";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { SelectTag } from "../../../components/Tag/SelectTab";
import { useModelData } from "../../../hook/useModelData";
import { useOptionData } from "../../../hook/useOptionData";
import { SelectComponent } from "../../../components/Select";
import {
  transformOptions,
  transformOriginalOptions,
  Option,
} from "../../../utils/option";
import { Loader } from "../../../components/Loader";
import { usePermissionHook } from "../../../hook/usePermissionHook";
import { CustomDialog } from "../../../components/modal/CustomDialog";

import "./styles.css";

const transformCategoryData = (data: any) => {
  const transformNode = (node: any) => {
    const transformedNode: any = {
      value: node.id,
      label: node.text,
    };

    if (node.items && node.items.length > 0) {
      transformedNode.items = node.items.map(transformNode);
    }

    return transformedNode;
  };

  return data.map(transformNode);
};

export const OptionsView: React.FC = () => {
  const { ID } = useParams();
  const navigate = useNavigate();

  const [option, setOption] = useState<any>(null);
  const [modelTags, setModelTags] = useState([]);
  const [optionTags, setOptionTags] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [logos, setLogos] = useState<Array<string | ArrayBuffer | null>>([
    null,
    null,
    null,
  ]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [imageData, setImageData] = useState<{ logo: any; index: number }>();

  const { showSnackBar } = useGlobalSnackBar();
  const { permissionReadOnly } = usePermissionHook();

  const { models } = useModelData();

  const { options } = useOptionData();

  useEffect(() => {
    const fetchOption = async () => {
      try {
        const optionData = await getOptionByID({ ID });
        setOption({ ...optionData });
        setLogos(optionData.images ? JSON.parse(optionData.images) : []);
        setModelTags(
          optionData.applicable_model_ids
            ? JSON.parse(optionData.applicable_model_ids)
            : []
        );
        setOptionTags(
          optionData.uncompatible_option_ids
            ? JSON.parse(optionData.uncompatible_option_ids)
            : []
        );
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch option", error);
        showSnackBar("Server error", "error");
      }
    };

    if (ID) {
      fetchOption();
    } else {
      setLoading(false);
    }
  }, [ID]);

  useEffect(() => {
    const orderedOptionData = transformOriginalOptions(
      transformOptions(options, true),
      true
    );
    const _orderedOptionData = orderedOptionData?.filter(
      (option: Option) => option.is_category
    );

    setCategoryOptions(transformCategoryData(_orderedOptionData));
  }, [options]);

  const permissionErrorMessage = () => {
    showSnackBar("Not allowed permission!", "error");
  };

  const handleUploadClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    const file = event.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("img", file);

    axios
      .post(process.env.REACT_APP_UPLOAD_URL || "", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("admin_access_token"),
        },
      })
      .then((response) => {
        const { filename } = response.data;
        setLogos((prevLogos) => {
          const updatedLogos = [...prevLogos];
          updatedLogos[index] = filename;
          return updatedLogos;
        });
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const handleRemoveClick = async (
    logo: string | ArrayBuffer | null,
    index: number
  ) => {
    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    if (!logo) return;
    setOpenModal(true);
    setImageData({ logo: logo, index: index });
  };

  const handleDelete = async () => {
    if (imageData?.logo) {
      await deleteFile({ file: logos[imageData.index] });
      setLogos((prevLogos) => {
        const updatedLogos = [...prevLogos];
        updatedLogos[imageData.index] = null;
        return updatedLogos;
      });
      setOpenModal(false);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    const formData = new FormData(event.target);
    const formValues: any = Object.fromEntries(formData.entries());
    formValues["images"] = JSON.stringify(logos);
    formValues["ID"] = ID;
    formValues["uncompatible_option_ids"] = JSON.stringify(optionTags);
    formValues["applicable_model_ids"] = JSON.stringify(modelTags);
    for (const key in formValues) {
      if (formValues[key] === "") {
        delete formValues[key];
      }
    }
    if (ID) {
      try {
        await updateOption(formValues);
        showSnackBar("Updated this option!");
        navigate("/options");
      } catch (err) {}
    } else {
      try {
        await createOption(formValues);
        showSnackBar("Created new option!");
        navigate("/options");
      } catch (err) {}
    }
  };

  const _orderedOptionData = useMemo(() => {
    return transformOriginalOptions(transformOptions(options, true), true);
  }, [options]);

  const orderedOptionData = useMemo(() => {
    return _orderedOptionData?.filter((option: Option) => !option.is_category);
  }, [_orderedOptionData]);

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} textAlign="center">
            <Input
              type="text"
              name="option_name"
              label="Title"
              size="medium"
              width={340}
              defaultValue={option?.option_name || ""}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Input
              type="text"
              name="part_number"
              label="Part #"
              size="medium"
              width={240}
              defaultValue={option?.part_number || ""}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          mt={3}
          pl={{ lg: 20, md: 2, xs: 2 }}
          pr={{ lg: 20, md: 2, xs: 2 }}
        >
          {logos.map((logo, index) => (
            <Grid item xs={3} key={index}>
              <Avatar
                sx={{ width: "100%", height: 300 }}
                variant="square"
                src={
                  typeof logo === "string"
                    ? `${process.env.REACT_APP_COMPANY_LOGO_URL}/${logo}`
                    : undefined
                }
                onClick={() => handleRemoveClick(logo, index)}
                className="uploadImage"
              >
                <input
                  accept="image/*"
                  id={`contained-button-file-${index}`}
                  type="file"
                  onChange={(e) => handleUploadClick(e, index)}
                  style={{ display: "none" }}
                />
                <label htmlFor={`contained-button-file-${index}`}>
                  <IconButton component="span">
                    <AddIcon />
                  </IconButton>
                </label>
              </Avatar>
            </Grid>
          ))}
          <Grid item xs={3} mt={0}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
                mt: 0.5,
              }}
              component="div"
            >
              <Typography sx={{ width: "80%" }} align="right" component="span">
                MSRP:
              </Typography>
              <Input
                name="MSRP"
                width={150}
                defaultValue={option?.MSRP || ""}
                type="number"
                inputProps={{
                  step: "any",
                  min: "0",
                }}
              />
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
                mt: 0.5,
              }}
              component="div"
            >
              <Typography sx={{ width: "80%" }} align="right" component="span">
                Dealer Price:
              </Typography>
              <Input
                name="dealer_price"
                width={150}
                defaultValue={option?.dealer_price || ""}
                type="number"
                inputProps={{
                  step: "any",
                  min: "0",
                }}
              />
            </Typography>
            <Typography variant="h5" align="center" mt={2.5} mb={1.5}>
              Dimensions
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
                mt: 0.5,
              }}
              component="div"
            >
              <Typography sx={{ width: "80%" }} align="right" component="span">
                Height:
              </Typography>
              <Input
                name="demention_height"
                width={150}
                defaultValue={option?.demention_height || ""}
                type="number"
                inputProps={{
                  step: "any",
                  min: "0",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">"</InputAdornment>
                  ),
                }}
              />
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
                mt: 0.5,
              }}
              component="div"
            >
              <Typography sx={{ width: "80%" }} align="right" component="span">
                Width:
              </Typography>
              <Input
                name="demention_width"
                width={150}
                defaultValue={option?.demention_width || ""}
                type="number"
                inputProps={{
                  step: "any",
                  min: "0",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">"</InputAdornment>
                  ),
                }}
              />
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
                mt: 0.5,
              }}
              component="div"
            >
              <Typography sx={{ width: "80%" }} align="right" component="span">
                Depth:
              </Typography>
              <Input
                name="demention_depth"
                width={150}
                defaultValue={option?.demention_depth || ""}
                type="number"
                inputProps={{
                  step: "any",
                  min: "0",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">"</InputAdornment>
                  ),
                }}
              />
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
                mt: 0.5,
              }}
              component="div"
            >
              <Typography sx={{ width: "80%" }} align="right" component="span">
                Rack Spaces:
              </Typography>
              <Input
                defaultValue={option?.demention_rack_spaces || ""}
                name="demention_rack_spaces"
                width={150}
                type="number"
                inputProps={{
                  step: "any",
                  min: "0",
                }}
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          pl={{ lg: 20, md: 2, xs: 2 }}
          pr={{ lg: 20, md: 2, xs: 2 }}
        >
          <Grid item xs={12}>
            <Typography variant="h5" mt={2.5} mb={1.5}>
              Option Category
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: 2.5,
                mb: 1.5,
                gap: 1,
              }}
              component="div"
            >
              <SelectComponent
                label="Category"
                width="200"
                defaultValue={option?.parent_id}
                name="parent_id"
                menuItems={categoryOptions}
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          mt={1}
          pl={{ lg: 20, md: 2, xs: 2 }}
          pr={{ lg: 20, md: 2, xs: 2 }}
        >
          <Grid item xs={6}>
            <Typography variant="h5" mb={1.5}>
              Applies to:
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: 2.5,
                mb: 1.5,
                gap: 1,
              }}
              component="div"
            >
              <SelectTag
                tags={modelTags}
                setTags={setModelTags}
                availableTags={models}
              />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" mb={1.5}>
              Not Compatible With:
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mt: 2.5,
                mb: 1.5,
                gap: 1,
              }}
              component="div"
            >
              <SelectTag
                tags={optionTags}
                setTags={setOptionTags}
                availableTags={orderedOptionData}
                currentID={ID}
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          pl={{ lg: 20, md: 2, xs: 2 }}
          pr={{ lg: 20, md: 2, xs: 2 }}
        >
          <Grid item xs={12}>
            <Typography variant="h5" mt={2.5} mb={1.5}>
              Description:
            </Typography>
            <Input
              name="description"
              rows={5}
              defaultValue={option?.description || ""}
            />

            <Typography align="center" mt={4.5} mb={1.5}>
              <Button sx={{ width: "150px" }} variant="contained" type="submit">
                Save
              </Button>
            </Typography>
          </Grid>
        </Grid>

        <CustomDialog
          title="Are you sure you want to change this image?"
          openModal={openModal}
          handleClose={() => setOpenModal(false)}
          handleAction={handleDelete}
          buttonTitle={"Yes"}
          children={undefined}
        />
      </Box>
    </>
  );
};
