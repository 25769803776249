import { del, get, post, patch } from "../helpers/admin_api_helper";
import { get as getUser } from "../helpers/user_api_helper";

export const getOption = () => get(`/option`);
export const getOptionByID = (data: { ID?: string }) =>
  get(`/option/${data.ID}`);
export const createOption = (data: {}) => post(`/option`, data);
export const updateOption = (data: any) => patch(`/option/${data.ID}`, data);
export const deleteOption = (data: { ID?: string }) =>
  del(`/option/${data.ID}`);
export const updateOptions = (data: any) => post(`/option/updateOptions`, data);
export const getSearchOptions = (data: {}) => post(`/option/searchAll`, data);

export const getUserOption = () => getUser(`/option`);
