import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { CollapsibleList } from "../../../components/CallapsibleList";
import { FAQDataType } from "../../../types/global.types";
import { getUserFAQ } from "../../../apis/faq";
import { Loader } from "../../../components/Loader";

export const UserFaq = () => {
  const [items, setItems] = useState<FAQDataType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean | false>(true);

  const fetchFAQs = async () => {
    try {
      const FAQData = await getUserFAQ();
      setItems(FAQData);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <Box sx={{ width: "100%" }}>
      <CollapsibleList items={items} />
    </Box>
  );
};
