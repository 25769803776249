import { useState, useEffect, useCallback } from "react";
import { getModels, getUserModels } from "../apis/model";

export const useModelData = (apiType = "admin"): any => {
  const [models, setModels] = useState<[]>([]);

  const fetchModelData = useCallback(async () => {
    try {
      let modelData
      if (apiType === "admin") {
        modelData = await getModels();
      } else {
        modelData = await getUserModels();
      }
      setModels(modelData);
    } catch (error) {
      console.error("Server error", error);
    }
  }, []);

  useEffect(() => {
    fetchModelData();
  }, [fetchModelData]);

  return { models };
};
