import axios from "axios";
import { jwtDecode } from "jwt-decode";

//pass new generated access token here
const user = localStorage.getItem("admin_access_token");
const token = "Bearer " + user;

const jsonUser: any = user ? jwtDecode(user) : [];

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => {
    if (
      response?.request?.responseURL?.includes("v1/admin/verify-token") ||
      response?.request?.responseURL?.includes("v1/admin/reset-pass") ||
      response?.request?.responseURL?.includes("v1/admin/sign-in")
    ) {
      return response;
    }
    if (!jsonUser?.first_name || !jsonUser?.last_name) {
      localStorage.removeItem("admin_access_token");
      window.location.href = "/admin/login";
    }
    return response;
  },
  (error) => {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("admin_access_token");
      window.location.href = "/admin/login";
    }
    Promise.reject(error);
  }
);

export async function get(url: string, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url: string, data: {}, config = {}) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url: string, data: {}, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function patch(url: string, data: {}, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
