import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  ListItem,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Input } from "../../../components/Input";
import DefaultTable from "../../../components/Table/DefaultTable";
import { usStates } from "../../../constants/Constant";
import { SelectComponent } from "../../../components/Select";
import { getDealerByID, updateDealer } from "../../../apis/dealer";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { Loader } from "../../../components/Loader";
import { getByDealerID } from "../../../apis/quote";
import { getQuoteNumber } from "../../../utils/quote";
import { checkActiveStatus } from "../../../utils/dealer";
import { useCompanyData } from "../../../hook/useCompanyData";
import { usePermissionHook } from "../../../hook/usePermissionHook";

interface StatCardProps {
  value: number | string;
  description: string;
}

const StatCard: React.FC<StatCardProps> = ({ value, description }) => (
  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
    <Card sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}>
      <CardContent sx={{ pb: "10px !important" }}>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

const headerData: string[] = ["Dealer", "Contact", "Quote #", "Date", "Status"];

export const DealerView: React.FC = () => {
  const { ID } = useParams();
  const navigate = useNavigate();

  const [dealer, setDealer] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [quotes, setQuotes] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [soldNumber, setSoldNumber] = useState<number>(0);

  const { showSnackBar } = useGlobalSnackBar();
  const { permissionReadOnly } = usePermissionHook();
  const { company } = useCompanyData();

  useEffect(() => {
    const fetchDealer = async () => {
      try {
        const optionData = await getDealerByID({ ID });
        setDealer({ ...optionData });
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch dealer", error);
        showSnackBar("Server error", "error");
      }
    };

    if (ID) {
      fetchDealer();
    } else {
      setLoading(false);
    }
  }, [ID]);

  const fetchQuotes = async () => {
    try {
      const {
        data: quoteData,
        counts,
        soldNumber,
      } = await getByDealerID({
        dealer_id: ID,
        page: page + 1,
        pageSize,
      });

      const transformedData = quoteData?.map((quote: any) => ({
        dealer: quote?.dealer_name,
        contact: quote?.contact_name,
        quotes: (
          <ListItem
            disablePadding
            component={Link}
            to={`/quote/${quote.quoteId}`}
          >
            {getQuoteNumber(quote.quoteId)}
          </ListItem>
        ),
        last_activity: moment(quote.createdAt).format("MM/DD/YYYY"),
        status: quote?.quote_status,
      }));

      setQuotes(transformedData);
      setCount(counts);
      setSoldNumber(soldNumber);

      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchQuotes();
  }, [ID, page, pageSize]);

  const permissionErrorMessage = () => {
    showSnackBar("Not allowed permission!", "error");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }

    const formData = new FormData(event.target);
    const formValues: any = Object.fromEntries(formData.entries());
    formValues["ID"] = ID;
    for (const key in formValues) {
      if (formValues[key] === "") {
        delete formValues[key];
      }
    }
    if (ID) {
      try {
        await updateDealer(formValues);
        showSnackBar("Updated this dealer!");
        navigate("/dealers");
      } catch (err) {}
    }
  };

  const dealerStatus = useMemo(() => {
    if (dealer && company && quotes?.length) {
      return checkActiveStatus(
        dealer.createdAt,
        company.active_dealer_setting_number +
          company.active_dealer_setting_date,
        quotes.length
      );
    }
  }, [dealer, company, quotes]);

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPageSize(Number(e.target.value));
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} textAlign="center">
            <Input
              label="Dealer Name"
              size="medium"
              name="dealer_name"
              defaultValue={dealer?.dealer_name}
              width={240}
            />
          </Grid>
          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{ display: "flex" }}
            gap={2}
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <Typography variant="h5" component="div">
              Account No: {dealer?.account_no}
            </Typography>
            <Tooltip title={dealerStatus ? dealerStatus : "Inactive"}>
              <div>
                <Switch
                  disabled
                  checked={dealerStatus === "Active" ? true : false}
                />
              </div>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={5}
          alignItems="center"
          justifyContent="center"
          mb={2}
        >
          <Grid item xs={3}>
            <Input
              name="contact_name"
              defaultValue={dealer?.contact_name}
              label="Contact Name:"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              label="Email Address:"
              size="small"
              name="email"
              defaultValue={dealer?.email}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={5}
          alignItems="center"
          justifyContent="center"
          mb={2}
        >
          <Grid item xs={3}>
            <Input
              name="address"
              defaultValue={dealer?.address}
              label="Address:"
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name="city"
              defaultValue={dealer?.city}
              label="City:"
              size="small"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={5}
          alignItems="center"
          justifyContent="center"
          mb={2}
        >
          <Grid item xs={3}>
            <SelectComponent
              menuItems={usStates}
              name="state"
              defaultValue={dealer?.state}
              label="State"
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              label="Country:"
              size="small"
              name="country"
              defaultValue={dealer?.country}
            />
          </Grid>
        </Grid>

        <Typography align="center" mt={4.5} mb={4.5}>
          <Button sx={{ width: "150px" }} variant="contained" type="submit">
            Save
          </Button>
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <StatCard value={count} description="Quotes Generated" />
          <StatCard value={soldNumber} description="Quotes Sold" />
          <StatCard
            value={
              dealer?.createdAt &&
              moment(dealer?.createdAt).format("MM/DD/YYYY")
            }
            description="Dealer Since"
          />
          <StatCard
            value={
              quotes?.[0]?.last_activity
                ? moment(quotes?.[0].last_activity).format("MM/DD/YYYY")
                : dealer?.createdAt &&
                  moment(dealer?.createdAt).format("MM/DD/YYYY")
            }
            description="Latest Activity"
          />
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h5" noWrap align="center" sx={{ mb: 2, mt: 3 }}>
          Recent Activity
        </Typography>
        <DefaultTable
          headerData={headerData}
          rowsData={quotes}
          rowsPerPage={pageSize}
          page={page}
          count={count}
          handleChangePage={(
            e: React.ChangeEvent<HTMLInputElement>,
            page: number
          ) => handleChangePage(page)}
          handleChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(e)
          }
        />
      </Box>
    </>
  );
};
